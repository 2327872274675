import { gql, useApolloClient } from '@apollo/client';
import { wrapRestMutation } from '@encoura/apollo-rest-utils';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

import REST from '~/constants/rest';
import TAG_IDS from '~/constants/tagIds';
import { UMS_ATTRIBUTES } from '~/constants/umsAttributes';
import { FiniteState } from '~/helpers/apollo/finiteQueryState';
import { asError } from '~/helpers/errors';
import sentryGraphqlRequestError from '~/helpers/SentryErrors/sentryGraphqlRequestError';
import useActiveUser from '~/hooks/useActiveUser';
import useDetailedPermissions from '~/hooks/useDetailedPermissions';
import Logo from '~/public/static/img/logo-color.png';

import { features, headline } from './features';
import { CheckmarkIcon, FeatureImage, LogoImage, MainContent, Preamble } from './styles';

export const HOME_DIALOG_NAME = 'HomeDialog';

export const HOME_MODAL_VERSION = 1;

export const UPDATE_DIALOG_DISMISS = gql`
  mutation UpdateDialogDismissMutation($uid: String!, $input: Input!) {
    updateDialogDismiss(uid: $uid, input: $input) {
      uid
    }
  }
`;

export interface IDialogProps {
  closeDialog: () => void;
  id: string;
  open: boolean;
}

const HomeDialog: React.FC<IDialogProps> = ({ closeDialog, id, open }: IDialogProps): React.ReactElement | null => {
  const client = useApolloClient();
  const activeUser = useActiveUser();
  const [dontShowDialogCheckbox, setDontShowDialogCheckbox] = React.useState<boolean>(false);

  // check if user has more than 1 school
  const getDetailedPermissions = useDetailedPermissions();
  const detailedPermissions = getDetailedPermissions.data?.detailedPermissions;
  const shouldSelectSchools = detailedPermissions && detailedPermissions.length > 1;
  const shouldOpenDialog = open && getDetailedPermissions.state === FiniteState.Ready;

  const [updateDialogDismiss] = wrapRestMutation<'updateDialogDismiss'>()(UPDATE_DIALOG_DISMISS, {
    endpoint: REST.PUT.USERS_BY_UID_ATTRIBUTES,
    variables: {
      input: {
        attributes: [
          {
            key: UMS_ATTRIBUTES.USER.HOME_MODAL,
            value: HOME_MODAL_VERSION,
          },
        ],
      },
      uid: activeUser?.uid as string,
    },
  });

  const handleCloseModal = async (): Promise<void> => {
    if (dontShowDialogCheckbox) {
      try {
        await updateDialogDismiss();
        await client.resetStore();
      } catch (unknownError: unknown) {
        const error = asError(unknownError);
        sentryGraphqlRequestError({ error, level: 'error', variables: {} });
      }
    }
    closeDialog();
  };

  return (
    <Dialog fullWidth id={id} maxWidth="lg" open={shouldOpenDialog} scroll="body">
      <DialogContent id={TAG_IDS.DIALOGS.HOME.CONTENT}>
        <LogoImage alt={HOME_DIALOG_NAME} aria-label={HOME_DIALOG_NAME} src={Logo.src} />

        <MainContent>
          <Preamble color="textPrimary">{headline}</Preamble>

          <Grid container direction="row" spacing={8} wrap="nowrap">
            {features?.map(feature => (
              <Grid item key={feature.heading} xs>
                <Typography align="center" color="primary" noWrap variant="h6">
                  {feature.heading}
                </Typography>
                <FeatureImage>
                  <img alt={feature.heading} src={feature.image} />
                </FeatureImage>
                {feature.bullets.map(bullet => (
                  <Grid container direction="row" key={bullet} spacing={1}>
                    <Grid item>
                      <CheckmarkIcon icon="general.checkmark.circle.outlined" />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="caption">{bullet}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </MainContent>
      </DialogContent>

      <DialogActions>
        <Grid alignItems="center" container>
          <Grid item xs>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dontShowDialogCheckbox}
                  id={TAG_IDS.DIALOGS.HOME.DONT_SHOW_ME_THIS_CHECKBOX}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setDontShowDialogCheckbox(event.target.checked);
                  }}
                />
              }
              label="Don't show this next time"
            />
          </Grid>
          <Grid container item justifyContent="center" xs>
            {shouldSelectSchools ? (
              <Button
                color="primary"
                id={TAG_IDS.DIALOGS.HOME.SELECT_SCHOOL_BUTTON}
                onClick={(): void => {
                  void handleCloseModal(); // eslint-disable-line no-void
                }}
              >
                Select school to begin
              </Button>
            ) : (
              <Button
                color="primary"
                id={TAG_IDS.DIALOGS.HOME.GET_STARTED_BUTTON}
                onClick={(): void => {
                  void handleCloseModal(); // eslint-disable-line no-void
                }}
              >
                Get Started
              </Button>
            )}
          </Grid>
          <Grid item xs>
            &nbsp;
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default HomeDialog;
