export const ROUTES = {
  ACCEPT_INVITATION: '/acceptInvitation',
  ACTIVATION: '/Activation',
  ADMINISTRATION: '/Administration',
  EDUCATOR_ACTIVATION: '/EducatorActivation',
  EDUCATOR_ACTIVATION_EMAIL_VERIFICATION: '/EducatorActivation/EmailVerification',
  EDUCATOR_ACTIVATION_SUPPORT: '/EducatorActivation/Support',
  FORGOT: '/forgot',
  IMPERSONATE: '/impersonate',
  INDEX: '/',
  INVITATION: '/invitation',
  LESSON_PLANS: '/LessonPlans',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MAINTENANCE: '/maintenance',
  MY_STUDENTS: '/MyStudents',
  OPTOUT: '/optout',
  PROFILE: '/ProfileManagement',
  REPORT_DASHBOARD: '/ReportDashboard',
  REPORTS: '/Reports',
  REPORTS_ACTE_REPORT: '/Reports/ACTEHighSchoolTeacherReport',
  REPORTS_ACTFL_REPORT: '/Reports/ACTFLHighSchoolTeacherReport',
  REPORTS_ART_REPORT: '/Reports/ARTHighSchoolTeacherReport',
  REPORTS_COF_JROTC_REPORT: '/Reports/COFJROTCHighSchoolTeacherReport',
  REPORTS_DECA_REPORT: '/Reports/DECAHighSchoolTeacherReport',
  REPORTS_FBLA_REPORT: '/Reports/FBLAHighSchoolTeacherReport',
  REPORTS_FCCLA_REPORT: '/Reports/FCCLAHighSchoolTeacherReport',
  REPORTS_MAA_REPORT: '/Reports/MAAHighSchoolTeacherReport',
  REPORTS_MO_REPORT: '/Reports/MOHighSchoolTeacherReport',
  REPORTS_NABSE_REPORT: '/Reports/NABSEHighSchoolTeacherReport',
  REPORTS_NAFME_REPORT: '/Reports/NAFMEHighSchoolTeacherReport',
  REPORTS_NATIONAL_REPORT: '/Reports/NationalReport',
  REPORTS_NCSS_REPORT: '/Reports/NCSSHighSchoolTeacherReport',
  REPORTS_SHAPE_AMERICA_REPORT: '/Reports/SHAPEHighSchoolTeacherReport',
  REPORTS_STEM_CONNECTOR_REPORT: '/Reports/STEMHighSchoolTeacherReport',
  REPORTS_SUPERINTENDENT_DISTRICT_POSTSECONDARY_REPORT: '/Reports/DistrictPostsecondaryReport',
  REPORTS_SUPERINTENDENT_REPORT: '/Reports/SuperintendentReport',
  REPORTS_USERS_REPORT: '/Reports/UsersReport',
  RESET_PASSWORD: '/ResetPassword',
  STYLES: '/styles',
};

export const BASE_64_REGEX = '(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?';
export const HS_REGEX = '([DH]S[0-9]{3}[0-9A-Z]{3}|Mentor)';
export const STUDENT_KEY_REGEX = '[0-9]*';

export const ROUTES_REGEX = {
  ACCEPT_INVITATION: '\\/acceptInvitation',
  ACTIVATION: `\\/Activation\\/${BASE_64_REGEX}`,
  ADMINISTRATION: `\\/Administration\\/${HS_REGEX}`,
  EDUCATOR_ACTIVATION: '\\/EducatorActivation',
  EDUCATOR_ACTIVATION_EMAIL_VERIFICATION: '\\/EducatorActivation\\/EmailVerification',
  EDUCATOR_ACTIVATION_SUPPORT: '\\/EducatorActivation\\/Support',
  FORGOT: '\\/forgot',
  IMPERSONATE: '\\/impersonate',
  INVITATION: '/invitation',
  LESSON_PLANS: `\\/LessonPlans\\/${HS_REGEX}`,
  LOGIN: '\\/login',
  LOGOUT: '\\/logout',
  MAINTENANCE: '\\/maintenance',
  MY_STUDENTS: `\\/MyStudents\\/${HS_REGEX}`,
  MY_STUDENTS_DETAILS: `\\/MyStudents\\/${HS_REGEX}\\/${STUDENT_KEY_REGEX}`,
  OPTOUT: '\\/optout',
  PROFILE: '\\/ProfileManagement',
  REPORT_DASHBOARD: `\\/ReportDashboard\\/${HS_REGEX}`,
  REPORTS_ACTE_REPORT: `\\/Reports\\/ACTEHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_ACTFL_REPORT: `\\/Reports\\/ACTFLHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_ART_REPORT: `\\/Reports\\/ARTHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_COF_JROTC_REPORT: `\\/Reports\\/COFJROTCHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_DECA_REPORT: `\\/Reports\\/DECAHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_FBLA_REPORT: `\\/Reports\\/FBLAHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_FCCLA_REPORT: `\\/Reports\\/FCCLAHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_MAA_REPORT: `\\/Reports\\/MAAHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_MO_REPORT: `\\/Reports\\/MOHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_NABSE_REPORT: `\\/Reports\\/NABSEHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_NAFME_REPORT: `\\/Reports\\/NAFMEHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_NATIONAL_REPORT: '\\/Reports\\/NationalReport',
  REPORTS_NCSS_REPORT: `\\/Reports\\/NCSSHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_SHAPE_AMERICA_REPORT: `\\/Reports\\/SHAPEHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_STEM_CONNECTOR_REPORT: `\\/Reports\\/STEMHighSchoolTeacherReport\\/${HS_REGEX}`,
  REPORTS_SUPERINTENDENT_DISTRICT_POSTSECONDARY_REPORT: '\\/Reports\\/DistrictPostsecondaryReport',
  REPORTS_SUPERINTENDENT_REPORT: '\\/Reports\\/SuperintendentReport',
  REPORTS_USERS_REPORT: '\\/Reports\\/UsersReport',
  REQUEST_ACCESS: '\\/RequestAccess',
  RESET_PASSWORD: `\\/ResetPassword\\/${BASE_64_REGEX}`,
  STYLES: '\\/styles',
};
