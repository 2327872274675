import { gql } from '@apollo/client';
import { wrapRestQuery } from '@encoura/apollo-rest-utils';

import { FEATURE_FLAGS_PREFIX } from '~/constants/featureFlags';
import REST from '~/constants/rest';
import { FiniteState, toFiniteState } from '~/helpers/apollo/finiteQueryState';

export const GET_FEATURE_FLAGS_QUERY = gql`
  query GetFeaureFlagsQuery {
    featureFlags {
      key
      value
    }
  }
`;

export interface IFeatureFlagReturn {
  data: boolean;
  loading: boolean;
}

type FeatureFlagRetriever = (flagName: string) => IFeatureFlagReturn;

const useFeatureFlags = (): FeatureFlagRetriever => {
  const queryState = wrapRestQuery<'featureFlags'>()(GET_FEATURE_FLAGS_QUERY, {
    endpoint: REST.GET.FEATURE_FLAGS,
  });

  const finiteQueryState = toFiniteState(queryState);

  const checkFlagState = (flagName: string): IFeatureFlagReturn => {
    if (finiteQueryState.state !== FiniteState.Ready) return { data: false, loading: true };
    const featureFlags = finiteQueryState.data.featureFlags;
    return {
      data: !!featureFlags?.find(element =>
        // TECH-DEBT: REMOVE THIS FIRST VERIFICATION LATER. THIS WAS DONE TO AVOID PROBLEMS WHEN DEPLOYING OUR SERVICES
        element.key === `${FEATURE_FLAGS_PREFIX}${flagName}` ||
        element.key === `${FEATURE_FLAGS_PREFIX}enable.${flagName}`
          ? element.value
          : false,
      ),
      loading: false,
    };
  };

  return checkFlagState;
};

export default useFeatureFlags;
