import Grid from '@mui/material/Grid';

import { styled } from '~/helpers/styled';

export const LegendContainer = styled(Grid)(() => ({
  margin: '56px 0',
}));

export const ReportCardsContainer = styled(Grid)(() => ({
  marginBottom: '32px',
}));
