import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Link from '~/components/Link';
import { styled } from '~/helpers/styled';

export const StyledLink = styled(Link)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.text.overLight.high,
  },
  color: theme.palette.text.overLight.high,
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
}));

export const StyledLinkGrid = styled(Grid)(() => ({
  padding: '8px 16px',
}));

export const StyledRoleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.overLight.medium,
}));

export const StyledLocaleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.overLight.medium,
}));
