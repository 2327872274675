import { Loading } from '@actinc/dls/components/Loading';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import { NamedGQLResult, RestResponse, wrapRestQuery } from '@encoura/apollo-rest-utils';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { first } from 'lodash';
import router from 'next/router';
import React from 'react';

import DialogContainer from '~/components/DialogContainer';
import NAMES from '~/constants/names';
import REST from '~/constants/rest';
import { ROUTES } from '~/constants/routes';
import TAG_IDS from '~/constants/tagIds';
import { toErrorAlert } from '~/helpers/errors';
import localCache from '~/helpers/localCache';
import sentryGraphqlRequestError from '~/helpers/SentryErrors/sentryGraphqlRequestError';
import { WalkMeInsightsTrack } from '~/helpers/walkme/WalkMeInsights';
import useAlert from '~/hooks/useAlert';
import useLogger from '~/hooks/useLogger';
import { EducationPartnerSignalContext } from '~/providers/EducationPartnerSignal';
import { HomeDialogSignalContext } from '~/providers/HomeDialogSignal';
import { IUser } from '~/types/auth';

import { FormLabel, GridItem, INPUT_WIDTH } from './styles';

export const USER_DISPLAY_LIMIT = 50;

interface IDialogProps {
  handleClose: () => void;
  open: boolean;
}

export const doImpersonation = async (
  apolloClient: ApolloClient<object>,
  resetState: () => void,
  userData: NamedGQLResult<'user', RestResponse<typeof REST.POST.USERS_BY_UID_IMPERSONATE>>,
): Promise<void> => {
  localCache.set(NAMES.IMPERSONATING, userData.user);
  const user = userData?.user.user as unknown as IUser | undefined;
  const userOnlySchool = (user?.organizations?.length ?? 0) === 1 ? first(user?.organizations) : undefined;
  localCache.set(NAMES.SCHOOL_ID, userOnlySchool?.organization?.externalId);

  resetState();
  await apolloClient.clearStore();

  // Impersonated user starts on Index page, that handles redirecting by useLandingPageRedirect hook
  await router.push({
    pathname: ROUTES.INDEX,
  });
};

export const GET_USERS_FOR_IMPERSONATION_QUERY = gql`
  query ImpersonateUserGetUsersQuery($ignore: String!, $searchStr: String!, $status: String!, $type: String!) {
    users(ignore: $ignore, searchStr: $searchStr, status: $status, type: $type) {
      email
      firstName
      lastName
      uid
    }
  }
`;

export const IMPERSONATE_USER_QUERY = gql`
  query ImpersonateUserQuery($uid: String!, $input: Input!) {
    user(uid: $uid, input: $input) {
      user
      sessionToken
    }
  }
`;

const ImpersonateDialog: React.FC<IDialogProps> = ({ handleClose, open }: IDialogProps): React.ReactElement | null => {
  const apolloClient = useApolloClient();

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const [emailSearchPattern, setEmailSearchPattern] = React.useState('');
  const [nameSearchPattern, setNameSearchPattern] = React.useState('');
  const [selectedUid, setSelectedUid] = React.useState<string | undefined>(undefined);
  const [impersonationRequested, setImpersonationRequested] = React.useState(false);
  const [impersonationLoading, setImpersonationLoading] = React.useState(false);

  const logger = useLogger('ImpersonateDialog');
  const { showErrorAlert } = useAlert();
  const HomeDialogSignal = React.useContext(HomeDialogSignalContext);
  const EducationPartnerSignal = React.useContext(EducationPartnerSignalContext);

  const wrappedRestQuery = wrapRestQuery<'users'>();

  const { data, loading } = wrappedRestQuery(GET_USERS_FOR_IMPERSONATION_QUERY, {
    endpoint: REST.GET.USERS,
    skip: emailSearchPattern.length < 3 && nameSearchPattern.length < 3,
    variables: {
      ignore: nameSearchPattern !== '' ? 'email|userName' : 'firstName|lastName|userName',
      limit: USER_DISPLAY_LIMIT,
      searchStr: nameSearchPattern !== '' ? nameSearchPattern : emailSearchPattern,
      status: 'Active',
      type: 'User', // Exclude LDAP
    },
  });
  const wrappedImpersonateQuery = wrapRestQuery<'user'>();

  const {
    data: userData,
    error: userError,
    loading: userLoading,
  } = wrappedImpersonateQuery(IMPERSONATE_USER_QUERY, {
    endpoint: REST.POST.USERS_BY_UID_IMPERSONATE,
    skip: !selectedUid || !impersonationRequested,
    variables: {
      input: {},
      uid: selectedUid as string,
    },
  });

  const resetState = (): void => {
    setAnchorEl(null);
    setEmailSearchPattern('');
    setNameSearchPattern('');
    setSelectedUid(undefined);
    setImpersonationLoading(false);
    setImpersonationRequested(false);
  };

  React.useEffect(() => {
    try {
      if (userLoading && !impersonationLoading) {
        setImpersonationLoading(userLoading);
      }

      if (userData) {
        // eslint-disable-next-line promise/prefer-await-to-then, promise/prefer-await-to-callbacks
        doImpersonation(apolloClient, resetState, userData)
          // eslint-disable-next-line promise/prefer-await-to-then
          .then(() => {
            WalkMeInsightsTrack('user_impersonation');
            EducationPartnerSignal.requestEducationPartnerDialog();
            HomeDialogSignal.requestHomeDialog();
            return handleClose();
          })
          // eslint-disable-next-line promise/prefer-await-to-then, promise/prefer-await-to-callbacks
          .catch((err: unknown) => {
            throw err;
          });
      } else if (userError) {
        throw userError;
      }
    } catch (err: unknown) {
      const { message } = toErrorAlert(err as Error);
      showErrorAlert(message);
      sentryGraphqlRequestError({ error: err, level: 'error', variables: {} });
      setImpersonationLoading(false);
      setImpersonationRequested(false);
    }
  }, [
    selectedUid,
    impersonationRequested,
    impersonationLoading,
    apolloClient,
    handleClose,
    logger,
    userLoading,
    userData,
    userError,
    HomeDialogSignal,
    EducationPartnerSignal,
    showErrorAlert,
  ]);

  const impersonate = (): void => {
    setImpersonationRequested(true);
  };

  const closeDialog = (): void => {
    handleClose();
    resetState();
  };

  const handleResultsClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <DialogContainer onClose={closeDialog} open={open} size="medium" tagIds={TAG_IDS.DIALOGS.IMPERSONATE} title="Impersonate">
      <Grid container spacing={0} sx={{ margin: 0, width: '100%' }}>
        <Grid alignItems="center" container direction="row">
          <GridItem item sx={{ marginLeft: 0 }}>
            <FormLabel noWrap>Find by Email</FormLabel>
          </GridItem>
          <GridItem item>
            <TextField
              autoComplete="off"
              id={TAG_IDS.DIALOGS.IMPERSONATE.FIND_BY_EMAIL_TEXTFIELD}
              InputProps={{
                id: TAG_IDS.DIALOGS.IMPERSONATE.FIND_BY_EMAIL_INPUT,
                name: TAG_IDS.DIALOGS.IMPERSONATE.FIND_BY_EMAIL_INPUT,
                onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
                  setNameSearchPattern('');
                  setEmailSearchPattern(e.target.value);
                  if (e.target.value.length >= 3) {
                    setAnchorEl(e.target);
                  }
                },
              }}
              placeholder="Find by Email"
              sx={{ width: INPUT_WIDTH }}
              value={emailSearchPattern}
            />
          </GridItem>
        </Grid>
        <Grid alignItems="center" container direction="row">
          <GridItem item sx={{ marginLeft: 0 }}>
            <FormLabel noWrap>Find by Name</FormLabel>
          </GridItem>
          <GridItem item>
            <TextField
              autoComplete="off"
              id={TAG_IDS.DIALOGS.IMPERSONATE.FIND_BY_NAME_TEXTFIELD}
              InputProps={{
                id: TAG_IDS.DIALOGS.IMPERSONATE.FIND_BY_NAME_INPUT,
                name: TAG_IDS.DIALOGS.IMPERSONATE.FIND_BY_NAME_INPUT,
                onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
                  setEmailSearchPattern('');
                  setNameSearchPattern(e.target.value);
                  if (e.target.value.length >= 3) {
                    setAnchorEl(e.target);
                  }
                },
              }}
              placeholder="Find by Name"
              sx={{ width: INPUT_WIDTH }}
              value={nameSearchPattern}
            />
          </GridItem>
        </Grid>
      </Grid>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        disableAutoFocus
        disableEnforceFocus
        id="impersonation-results"
        keepMounted
        onClose={handleResultsClose}
        onMouseLeave={handleResultsClose}
        open={!!anchorEl && !!(data || loading)}
        sx={{ maxHeight: '20rem' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        transitionDuration={0}
      >
        <List sx={{ minWidth: INPUT_WIDTH }}>
          {(loading || impersonationLoading) && (
            <ListItem>
              <Loading />
            </ListItem>
          )}
          {!impersonationLoading &&
            data &&
            data.users.slice(0, USER_DISPLAY_LIMIT).map(user => (
              <ListItemButton
                key={user.uid}
                onClick={impersonate}
                onFocusVisible={(): void => setSelectedUid(user.uid)}
                onMouseOver={(): void => setSelectedUid(user.uid)}
                selected={selectedUid === user.uid}
              >
                <Grid container direction="row">
                  <Grid item sm={4}>
                    <Typography>
                      {user.firstName} {user.lastName}
                    </Typography>
                  </Grid>
                  <Grid item sm={8}>
                    <Typography>{user.email}</Typography>
                  </Grid>
                </Grid>
              </ListItemButton>
            ))}
          {!impersonationLoading && data && data.users && data.users.length === 0 && (
            <Grid container direction="row">
              <Grid item sm={4}>
                <Typography sx={{ margin: 1, marginLeft: 2 }}>No results found</Typography>
              </Grid>
            </Grid>
          )}
        </List>
      </Popover>
    </DialogContainer>
  );
};

export default ImpersonateDialog;
