import React from 'react';

import Logger from '~/helpers/logger';
import { ILogger } from '~/types';

const useLogger = (name = 'LOGGER'): ILogger => {
  const loggerInstance = React.useRef<ILogger | null>(null);
  loggerInstance.current ||= new Logger(name);

  return loggerInstance.current;
};

export default useLogger;
