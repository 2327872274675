import React from 'react';

import AppLayout from '~/layouts/AppLayout';

import Maintenance from './Maintenance';

const Page: React.FC = (): React.ReactElement<unknown> => {
  return (
    <AppLayout forceLogout hideSearchSchools hideUserMenu isHeaderLogoClickable={false} showFooter={false}>
      <Maintenance />
    </AppLayout>
  );
};

export default Page;
