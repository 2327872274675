import * as React from 'react';

export interface IEducationPartnerSignalContext {
  clearRequest: () => void;
  requested: boolean;
  requestEducationPartnerDialog: () => void;
}

export const EducationPartnerSignalContext = React.createContext<IEducationPartnerSignalContext>({
  clearRequest: () => {
    throw new Error('Unimplemented');
  },
  requested: false,
  requestEducationPartnerDialog: () => {
    throw new Error('Unimplemented');
  },
});

export const EducationPartnerSignalProvider = EducationPartnerSignalContext.Provider;

export interface IEducationPartnerSignalProviderComponentProps {
  children: React.ReactNode;
}

export const EducationPartnerSignalProviderComponent: React.FC<IEducationPartnerSignalProviderComponentProps> = ({ children }) => {
  const [requested, setRequested] = React.useState(false);
  const requestEducationPartnerDialog = (): void => setRequested(true);
  const clearRequest = (): void => setRequested(false);

  return <EducationPartnerSignalProvider value={{ clearRequest, requested, requestEducationPartnerDialog }}>{children}</EducationPartnerSignalProvider>;
};
