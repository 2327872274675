import ListItemButton from '@mui/material/ListItemButton';

import Link from '~/components/Link';
import { styled } from '~/helpers/styled';

export const ListItemRootButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  textAlign: 'right',
  width: '100%',
}));

export const MenuItemLink = styled(Link)(() => ({
  '&:hover': {
    color: 'inherit',
  },
  color: 'inherit',
  width: '100%',
}));
