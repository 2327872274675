import homeFeature01Img from '~/public/static/img/homefeatures/homeFeature01.png';
import homeFeature02Img from '~/public/static/img/homefeatures/homeFeature02.png';
import homeFeature03Img from '~/public/static/img/homefeatures/homeFeature03.png';

export const headline =
  'Engage students and families - in-person or remotely - to guide the ' +
  'planning process with post-secondary progress monitoring, college and ' +
  'scholarship match insights, and actionable data at the student, school ' +
  'and district level.';

export interface IFeatures {
  bullets: string[];
  heading: string;
  image: string;
}

export const features: IFeatures[] = [
  {
    bullets: [
      'Connect to students with real-time updates from the student-facing myOptions web and iOS app.',
      'Track how your students are progressing on their milestone checklists tailored to their grade levels and semesters.',
    ],
    heading: 'Manage Your Students',
    image: homeFeature01Img.src,
  },
  {
    bullets: [
      'Analyze trends and summary reports on college and career interests at the student, school, ' +
        'and district level with a comparison to national trends.',
      'Take action using data to help shape your programs within a changing student landscape.',
    ],
    heading: 'Take Action with real-time data',
    image: homeFeature02Img.src,
  },
  {
    bullets: [
      'Research, analysis, and thought leadership on relevant topics, such as Student Mindsets™ in the post-secondary planning process.',
      'Insights, best practices, and top publications.',
      'Industry leading research with recommendations on how to apply it within school counseling practices.',
    ],
    heading: 'Lead with Research',
    image: homeFeature03Img.src,
  },
];
