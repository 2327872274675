import { NextPage } from 'next';
import React from 'react';

import Meta from './meta';
import Page from './Page';

const Maintenance: NextPage = (): React.ReactElement<unknown> => {
  return (
    <>
      <Page />

      <Meta />
    </>
  );
};

export default Maintenance;
