import { operations, paths } from '~/constants/__generatedSwaggerTypes';

type RESTSchoolStudent = operations['getStudentAppMan']['responses'][200]['content']['application/json'][number];
type RESTUserSchool = Required<RESTSchoolStudent>['user_schools'][number];

export type StudentApplicationCollege = Required<RESTUserSchool>['school'] & {
  initials: string;
};

export type RESTCollegeApplication = NonNullable<
  operations['getStudentAppManDetails']['responses'][200]['content']['application/json']['items']
>[number];

// FIXME: This is a hack to make the type in line with the result.
// E4E-service swagger needs to be updated
export type UserSchool = RESTUserSchool & {
  school?: Required<RESTUserSchool>['school'] & {
    initials?: string;
  };
};

export type SchoolStudent = RESTSchoolStudent & {
  user_schools?: Required<UserSchool>['school'][];
};

export type CollegeApplication = RESTCollegeApplication & {
  school?: StudentApplicationCollege;
};

export type MentoringStudent =
  operations['getEducatorStudents']['responses'][200]['content']['application/json'][number];

export type StudentCollegeMatchData =
  operations['collegeMatchStudentData']['responses'][200]['content']['application/json'];

export enum HighSchoolGrade {
  MIDDLE_SCHOOL,
  FRESHMAN,
  SOPHOMORE,
  JUNIOR,
  SENIOR,
}

export enum HighSchoolSemester {
  FallWinter = 1,
  SpringSummer = 2,
}

export type StudentTestScore =
  paths['/students/{studentKey}/test-scores']['get']['responses']['200']['content']['application/json'];

export interface IParsedTestScore {
  maxTotalScore: number;
  subjectFields: { grade: number; name: string }[];
  testName: string;
  totalScore: number | null;
}

export type IColleges =
  paths['/students/{studentKey}/colleges']['get']['responses']['200']['content']['application/json']['matched_colleges'];

export type IStudentColleges = NonNullable<IColleges>[number];

export interface IRankedCollege extends IStudentColleges {
  id?: string;
  rank?: number;
}

export type StudentsCompleteData =
  operations['getEducatorStudentsCompleteData']['responses']['200']['content']['application/json'];

export type IScholarships =
  operations['getEducatorStudentsCompleteData']['responses']['200']['content']['application/json']['scholarships'];

export type IStudentScholarships = NonNullable<IScholarships>[number];

export type Scores = 'ACT' | 'PSAT' | 'SAT';
export interface IScores {
  label: string;
  score: number | '-';
}

export interface IArea {
  code: string;
  description?: string;
  title: string;
}

export interface IMatchedInfo extends IArea {
  isPossibleMajor?: boolean;
  isMatched?: boolean;
  isSaved: boolean;
  parentArea?: IArea;
  url: string;
}

export interface IMatchedInfoByArea extends IArea {
  childrenItems: IMatchedInfo[];
}
