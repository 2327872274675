import { gql } from '@apollo/client';
import { NamedGQLResult, wrapRestQuery } from '@encoura/apollo-rest-utils';

import { REST } from '~/constants/rest';
import { FiniteQueryState, toFiniteState } from '~/helpers/apollo/finiteQueryState';
import useToken from '~/hooks/useToken';
import { DetailedPermissions } from '~/types';

type QueryType = NamedGQLResult<'detailedPermissions', DetailedPermissions>;

const useDetailedPermissions = (): FiniteQueryState<QueryType> => {
  const token = useToken();

  const DETAILED_PERMISSIONS_QUERY = gql`
    query WithDetailedPermissionsQuery {
      detailedPermissions {
        organization {
          city
          externalId
          name
          role
          uid
          state
        }
        permissions {
          applicationManagement
          credentialSubmission
          e4eReportAdmin
          highSchoolAdmin
          mentoring
          reportACTE
          reportACTFL
          reportART
          reportCOF
          reportDECA
          reportSUPERINTENDENT
          reportFBLA
          reportFCCLA
          reportMAA
          reportMCS
          reportMCO
          reportNABSE
          reportNAFME
          reportNCSS
          reportSHAPE
          reportSTEM
          reportSUPERINTENDENT
        }
      }
    }
  `;

  const queryState = wrapRestQuery<'detailedPermissions'>()(DETAILED_PERMISSIONS_QUERY, {
    endpoint: REST.GET.DETAILED_PERMISSIONS,
    skip: !token,
  });

  return toFiniteState(queryState);
};

export default useDetailedPermissions;
