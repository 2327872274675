import moment from 'moment';

import { HighSchoolGrade, HighSchoolSemester, StudentApplicationCollege } from '~/types/students';

export const COLLEGE_URL = {
  APPLY: {
    TYPE: 'A',
    TYPE_DESC: 'Apply',
  },
  INQUIRY: {
    TYPE: 'I',
    TYPE_DESC: 'Inquiry',
  },
  MAIN: {
    TYPE: 'M',
    TYPE_DESC: 'Main',
  },
  SCHEDULE: {
    TYPE: 'S',
    TYPE_DESC: 'Schedule Visit',
  },
};

export const getCollegeApplicationExternalUrl = (college: StudentApplicationCollege): string | undefined => {
  const collegeUrlObject = college.urls ? college.urls.find(url => url.type === COLLEGE_URL.APPLY.TYPE) : undefined;

  return collegeUrlObject?.value;
};

export const getDefaultGradYear = (): number => {
  const now = moment().utc();

  // senior grad year shifts from current year to next on July (which is month 6 on 0 indexed month)
  return now.month() > 5 ? now.year() + 1 : now.year();
};

export const getDefaultHighSchoolYears = (): (number | 'UNKNOWN')[] => {
  const defaultYear = getDefaultGradYear();
  const years = [0, 1, 2, 3, 4, 5].map(n => n + defaultYear) as (number | 'UNKNOWN')[];
  years.push('UNKNOWN');
  return years;
};

export const getDefaultHighSchoolYearsOnly = (): (number | 'UNKNOWN')[] => {
  const defaultYear = getDefaultGradYear();
  const years = [0, 1, 2, 3, 4, 5].map(n => n + defaultYear) as (number | 'UNKNOWN')[];
  return years;
};

export const getDefaultDataSources = (): string[] => {
  return ['DIGITAL_SURVEY', 'PAPER_SURVEY', 'STUDENT_APP'];
};

export const getDataSourceName = (dataSource?: string): string => {
  switch (dataSource) {
    case 'DIGITAL_SURVEY':
      return 'Digital form';
    case 'PAPER_SURVEY':
      return 'Paper form';
    case 'STUDENT_APP':
      return 'Student app';
    default:
      return 'Unknown';
  }
};

export const getDataSourceNameByProfileSource = (dataSource?: string, profileSource?: string): string => {
  if (profileSource === 'MCO') {
    switch (dataSource) {
      case 'DIGITAL_SURVEY':
        return 'DIGITAL_SURVEY';
      case 'PAPER_SURVEY':
        return 'PAPER_SURVEY';
      default:
        return 'Unknown';
    }
  }

  if (profileSource === 'ACT') {
    return 'STUDENT_APP';
  }

  return 'Unknown';
};

export const getDataSourceType = (dataSource?: string): 'Survey' | 'Student app' | 'Unknown' => {
  switch (dataSource) {
    case 'Digital form':
      return 'Survey';
    case 'Paper form':
      return 'Survey';
    case 'Student app':
      return 'Student app';
    default:
      return 'Unknown';
  }
};

export const getYearsToGraduate = (gradYear: number): number => {
  const now = moment().utc();
  const graduationReference = moment(`${gradYear}-06-30`).utc();
  return graduationReference.diff(now, 'years');
};

export const getDefaultYearFilterByGradYear = (gradYear?: number): HighSchoolGrade => {
  if (!gradYear) return HighSchoolGrade.SENIOR;

  const yearsToGraduate = getYearsToGraduate(gradYear);
  // <= 0 === senior
  // 1    === junior
  // 2    === sophomore
  // >= 3 === freshman

  if (yearsToGraduate <= 0) return HighSchoolGrade.SENIOR;
  if (yearsToGraduate === 1) return HighSchoolGrade.JUNIOR;
  if (yearsToGraduate === 2) return HighSchoolGrade.SOPHOMORE;
  if (yearsToGraduate === 3) return HighSchoolGrade.FRESHMAN;
  return HighSchoolGrade.MIDDLE_SCHOOL;
};

export const getDefaultSemester = (): HighSchoolSemester => {
  return moment().utc().month() >= 6 ? HighSchoolSemester.FallWinter : HighSchoolSemester.SpringSummer;
};
