import { trimStart } from 'lodash';

export const normalizePath = (path?: string): string => {
  return path ? trimStart(path.split('?')[0].toLowerCase(), '/') : '';
};

export const isPathEquivalent = (path1?: string, path2?: string): boolean => {
  return normalizePath(path1) === normalizePath(path2);
};

export const isRegexPathEquivalent = (paramaterPath: string, actualPath: string): boolean => {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const re = new RegExp(`^${paramaterPath}$`, 'g');
  return !!actualPath.split('?')[0].match(re);
};
