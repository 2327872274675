export const USER_TYPES = {
  ADMIN: 'Admin' as const,
  LDAP: 'LdapUser' as const,
};

export default USER_TYPES;

export const WALKME_USER_ROLES: Record<string, string> = {
  coach: 'Coach',
  counselor: 'Counselor',
  ldap_user: 'Internal User',
  other: 'Other',
  partner: 'Partner User ',
  principal: 'Principal',
  superintendent: 'Superintendent',
  teacher: 'Teacher',
  teaching_assistant: 'Teaching Assistant',
};
