import NAMES from '~/constants/names';
import localCache from '~/helpers/localCache';
import { IImpersonating } from '~/types';

export const setToken = (token?: string, rootUser = true): void => {
  if (rootUser) {
    localCache.set(NAMES.TOKEN, token);
  } else {
    const impersonating = localCache.get<IImpersonating>(NAMES.IMPERSONATING);
    if (impersonating) {
      if (token) {
        impersonating.sessionToken = token;
        localCache.set(NAMES.IMPERSONATING, impersonating);
      } else {
        localCache.set(NAMES.IMPERSONATING, undefined);
      }
    }
  }
};

export const clearTokens = (): void => {
  setToken(undefined);
  setToken(undefined, false);
  localCache.set(NAMES.SCHOOL_ID, undefined);
};

export const useToken = (rootUser = false): string | undefined => {
  const rootToken = localCache.get(NAMES.TOKEN);
  if (rootUser) {
    return rootToken;
  }
  const impersonating = localCache.get<IImpersonating>(NAMES.IMPERSONATING);
  return impersonating?.sessionToken ?? rootToken;
};

export default useToken;
