import Grid from '@mui/material/Grid';
import { StaticImageData } from 'next/image';
import React from 'react';

import Gear from '~/public/static/img/gear.svg';

import { ContentHeader, ContentLayoutGrid, ContentMessage } from './styles';

const Maintenance: React.FC = (): React.ReactElement<unknown> => {
  return (
    <ContentLayoutGrid container direction="column">
      <Grid alignContent="center" item>
        <img alt="Maintenance" src={(Gear as StaticImageData).src} style={{ marginTop: '200px' }} />
      </Grid>
      <Grid item>
        <ContentHeader variant="h1">Maintenance Mode</ContentHeader>
      </Grid>
      <Grid item>
        <ContentMessage>We apologize for the inconvenience.</ContentMessage>
      </Grid>
      <Grid item>
        <ContentMessage>Our site is currently undergoing maintenance, but will return shortly.</ContentMessage>
      </Grid>
      <Grid item>
        <ContentMessage>Thank you for your patience.</ContentMessage>
      </Grid>
    </ContentLayoutGrid>
  );
};

export default Maintenance;
