import { QueryResult, gql } from '@apollo/client';
import { NamedGQLResult, wrapRestQuery } from '@encoura/apollo-rest-utils';

import { REST } from '~/constants/rest';
import USER_TYPES from '~/constants/userTypes';
import { MentoringStudent } from '~/types/students';

import useActiveUser from '../useActiveUser';

export const GET_MY_STUDENTS_QUERY = gql`
  query MyStudentsPageQuery(
    $dataSources: String!
    $gradYears: String!
    $hsId: String!
    $inviteCode: String
    $limit: Int!
    $offset: Int!
    $fetchJustStudentIds: Boolean
    $search: String!
    $sort: String!
    $surveyEndDate: String!
    $surveyStartDate: String!
    $uid: String!
  ) {
    getEducatorStudents(
      dataSources: $dataSources
      gradYears: $gradYears
      hsId: $hsId
      inviteCode: $inviteCode
      limit: $limit
      offset: $offset
      fetchJustStudentIds: $fetchJustStudentIds
      search: $search
      sort: $sort
      surveyStartDate: $surveyStartDate
      surveyEndDate: $surveyEndDate
      uid: $uid
    ) {
      student_key
      first_name
      last_survey_date
      last_name
      graduation_year
      most_recent_data_source
    }
    headers(
      dataSources: $dataSources
      gradYears: $gradYears
      hsId: $hsId
      inviteCode: $inviteCode
      limit: $limit
      offset: $offset
      fetchJustStudentIds: $fetchJustStudentIds
      search: $search
      sort: $sort
      surveyStartDate: $surveyStartDate
      surveyEndDate: $surveyEndDate
      uid: $uid
    ) {
      _id
      totalCount
      totalPages
    }
  }
`;

export interface IMyStudentQueryParams {
  dataSources: string;
  gradYears: string;
  hsId: string;
  inviteCode: string;
  limit: number;
  loading?: boolean;
  offset: number;
  search: string;
  sort: string;
  surveyEndDate: string;
  surveyStartDate: string;
  uid: string;
  fetchJustStudentIds: boolean;
}

type QueryType = QueryResult<NamedGQLResult<'getEducatorStudents', MentoringStudent[]>>;

export const useMyStudentQuery = (queryParams: IMyStudentQueryParams): QueryType => {
  const activeUser = useActiveUser();
  const query = wrapRestQuery<'getEducatorStudents'>()(GET_MY_STUDENTS_QUERY, {
    endpoint: REST.GET.EDUCATOR_BY_UID_STUDENTS_BY_HSID,
    skip: activeUser?.type === USER_TYPES.LDAP || queryParams.loading,
    variables: { ...queryParams },
  }) as unknown as QueryType;

  return query;
};

export default useMyStudentQuery;
