import { boolean } from 'boolean';
import dynamic from 'next/dynamic';
import React from 'react';

import ENV from '~/constants/env';

const DebugMenuProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const displayDebugMenu = boolean(ENV.ENABLE_DEBUG_MENU);

  const DebugMenuComponent = React.useMemo(
    () => (displayDebugMenu ? dynamic(() => import('~/components/DebugMenu'), { ssr: false }) : null),
    [displayDebugMenu],
  );

  return (
    <>
      {children}
      {DebugMenuComponent && <DebugMenuComponent />}
    </>
  );
};

export default DebugMenuProvider;
