import { OverridableComponent, OverridableTypeMap, OverrideProps } from '@mui/material/OverridableComponent';
import { CreateMUIStyled, styled as muiStyled, Theme } from '@mui/material/styles';
import React from 'react';

export type AsStyledProps<T> = {
  [K in keyof T as `$${string & K}`]: T[K];
};

export const forwardNonTransientProps = (propName: string): boolean => !propName.startsWith('$');

/**
 * Customized styled helper which omits transient props (starting with $)
 * which are used for styling only in styled components.
 */
export const styled: CreateMUIStyled<Theme> = ((...args: Parameters<typeof muiStyled>) =>
  muiStyled(args[0], {
    shouldForwardProp: forwardNonTransientProps,
    ...args[1],
  })) as unknown as CreateMUIStyled<Theme>;
/**
 * Function generator that will generate a `styled` function that has a specific theme type injected. E.g.,
 * const styled = createThemeStyled(THEME_ENCOURAGE_E4E);
 * const StyledTypography = styled(Typography)(({ theme }) => ({ color: theme.palette.branding.teal }));
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createThemeStyled = <T extends Theme = Theme>(_theme?: T): CreateMUIStyled<T> => styled as CreateMUIStyled<T>;

/**
 * Helper function for MUI components which receives a "component" prop to
 * change the node type rendered for styled components.
 */
export function withNode<T extends OverridableTypeMap, N extends React.ElementType>(
  Component: OverridableComponent<T>,
  node: N,
): React.FC<OverrideProps<T, N>> {
  const ComponentWithNode = ((props: OverrideProps<T, N>) => <Component {...props} component={node} />) as React.FC<OverrideProps<T, N>>;
  ComponentWithNode.displayName = (Component as React.FC).displayName;
  return ComponentWithNode;
}
