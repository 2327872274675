import Grid from '@mui/material/Grid';
import moment from 'moment';
import { FC, ReactElement } from 'react';

import NAMES from '~/constants/names';
import USER_TYPES from '~/constants/userTypes';
import useActiveUser from '~/hooks/useActiveUser';
import useLocalStorage from '~/hooks/useLocalStorage';
import { Footer } from '~/layouts/AppLayout/styles';

import LdapSchoolSearch from './components/LdapSchoolSearch';
import UserSchoolSearch from './components/UserSchoolSearch';
import { RootFootContainer, RootPaperContainer } from './styles';

interface IProps {
  closeDialog?: () => void;
}

const SchoolSearch: FC<IProps> = (props): ReactElement<unknown> => {
  const { closeDialog } = props;
  const currentUser = useActiveUser();

  useLocalStorage(NAMES.IMPERSONATING, null);
  const isUserLdap = currentUser?.type === USER_TYPES.LDAP;
  /**
   * closeDialog indicates that the search should be done using a dialog so we render a different layout for these
   */
  if (closeDialog) {
    return isUserLdap ? <LdapSchoolSearch closeDialog={closeDialog} /> : <UserSchoolSearch closeDialog={closeDialog} />;
  }

  return (
    <Grid container item paddingLeft={3} xs={12}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item md={6} sm={8}>
            <RootPaperContainer>
              {isUserLdap ? <LdapSchoolSearch closeDialog={closeDialog} /> : <UserSchoolSearch closeDialog={closeDialog} />}
            </RootPaperContainer>
          </Grid>
        </Grid>
      </Grid>
      <RootFootContainer alignContent="space-evenly" container>
        <Footer variant="caption">©Copyright {moment().format('YYYY')}. Encoura, LLC. All rights reserved.</Footer>
      </RootFootContainer>
    </Grid>
  );
};

export default SchoolSearch;
