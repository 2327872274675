const extractQueryParams = (route: string): Record<string, string> | undefined => {
  if (!route.includes('?')) {
    return undefined;
  }

  const queryString = route.split('?')[1];
  const result = queryString
    .split('&')
    .filter(x => x.includes('='))
    .reduce((prev: Record<string, string>, curr: string): Record<string, string> => {
      const nameValue = curr.split('=');
      const next = { ...prev };
      next[nameValue[0]] = nameValue[1];
      return next;
    }, {});

  return result;
};

export default extractQueryParams;
