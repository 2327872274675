import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { styled } from '~/helpers/styled';

export const InnerContainer = styled(Grid)(() => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: 500,
}));

export const OuterContainer = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

export const RootTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));

export const RootTypographyTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  textAlign: 'center',
}));
