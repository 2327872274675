import { useApolloClient } from '@apollo/client';
import { wrapRestQuery } from '@encoura/apollo-rest-utils';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import { compact, head } from 'lodash';
import router from 'next/router';
import { FC, MouseEvent, ReactElement, useState } from 'react';

import Avatar from '~/components/Avatar';
import MenuItem, { IMenuItem } from '~/components/MenuItem';
import EXTERNAL_LINKS from '~/constants/externalLinks';
import MODULES from '~/constants/modules';
import NAMES from '~/constants/names';
import REST from '~/constants/rest';
import { ROUTES } from '~/constants/routes';
import TAG_IDS from '~/constants/tagIds';
import { toErrorAlert } from '~/helpers/errors';
import localCache from '~/helpers/localCache';
import sentryRoutingError from '~/helpers/SentryErrors/sentryRoutingError';
import useActiveUser from '~/hooks/useActiveUser';
import useAlert from '~/hooks/useAlert';
import { setToken } from '~/hooks/useToken';
import { IImpersonating } from '~/types';
import { STOP_IMPERSONATION_USER } from '~/views/DeepLinkImpersonate/Page/components/StopImpersonate';

import ImpersonateDialog from './ImpersonateDialog';
import { MenuRootGrid, OverlayBox, UserFullNameTypography } from './styles';

interface IProps {
  schoolId?: string;
}

const UserMenu: FC<IProps> = ({ schoolId }: IProps): ReactElement | null => {
  const me = useActiveUser();
  const apolloClient = useApolloClient();

  const { showErrorAlert } = useAlert();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const firstName = me?.firstName ?? '';
  const lastName = me?.lastName ?? '';
  const initials = compact([head(firstName), head(lastName)]).join('');

  const [impersonateDialogOpen, setImpersonateDialogOpen] = useState(false);
  const openImpersonateDialog = (): void => {
    setImpersonateDialogOpen(true);
  };

  const impersonating = localCache.get<IImpersonating>(NAMES.IMPERSONATING);

  const handleImpersonateDialogClose = (): void => {
    setImpersonateDialogOpen(false);
  };

  const [endingImpersonation, setEndingImpersonation] = useState(false);
  const endImpersonation = (): void => {
    setEndingImpersonation(true);
  };

  const wrappedEndImpersonateQuery = wrapRestQuery<'newUserToken'>();

  const { data: endImpersonationData } = wrappedEndImpersonateQuery(STOP_IMPERSONATION_USER, {
    endpoint: REST.DELETE.USERS_BY_UID_IMPERSONATE,
    skip: !endingImpersonation || !impersonating || !impersonating?.user?.uid,
    variables: {
      uid: impersonating?.user?.uid as string,
    },
  });

  const resetAndRedirect = async (): Promise<void> => {
    await router.push({
      pathname: ROUTES.INDEX,
    });
    await apolloClient.resetStore();
  };

  if (endImpersonationData) {
    setToken(endImpersonationData.newUserToken.sessionToken);
    setToken(undefined, false);
    localCache.set(NAMES.SCHOOL_ID, undefined);

    resetAndRedirect()
      // eslint-disable-next-line promise/prefer-await-to-then, promise/prefer-await-to-callbacks
      .catch((err: unknown) => {
        const { message } = toErrorAlert(err as Error);
        showErrorAlert(message);
        sentryRoutingError({ error: err, message, to: router.asPath });
      });

    setEndingImpersonation(false);
  }

  const menuActions: IMenuItem[] = [
    {
      href: ROUTES.PROFILE,
      id: TAG_IDS.USER_MENU.PROFILE_MANAGEMENT_ACTION,
      title: 'Account',
      visible: !!schoolId,
    },
    {
      id: TAG_IDS.USER_MENU.END_IMPERSONATE_ACTION,
      onClick: endImpersonation,
      title: 'End Impersonation',
      visible: me?.rootUser?.type === 'LdapUser',
    },
    {
      externalLink: true,
      href: EXTERNAL_LINKS.REQUEST_ASSISTANCE,
      id: TAG_IDS.USER_MENU.HELP_ACTION,
      title: 'Help',
    },
    {
      id: TAG_IDS.USER_MENU.IMPERSONATE_ACTION,
      onClick: openImpersonateDialog,
      title: 'Impersonate',
      visible: me?.type === 'LdapUser',
    },
    {
      href: `${ROUTES.ADMINISTRATION}/${schoolId ?? ''}`,
      id: TAG_IDS.USER_MENU.ADMINISTRATION_ACTION,
      module: MODULES.HIGH_SCHOOL_ADMIN,
      title: 'User Administration',
      visible: !!schoolId,
    },
    {
      href: ROUTES.LOGOUT,
      id: TAG_IDS.USER_MENU.LOGOUT_ACTION,
      title: 'Sign Out',
    },
  ];

  const open = Boolean(anchorEl);

  const sortedMenuActions = menuActions.sort(menuAction => {
    if (menuAction.title === 'Sign out') return 1;
    return 0;
  });

  return (
    <>
      <MenuRootGrid
        $open={open}
        alignItems="center"
        container
        data-testid={TAG_IDS.USER_MENU.MENU_DROPDOWN}
        direction="row"
        id={TAG_IDS.USER_MENU.MENU_DROPDOWN}
        onClick={(event): void => {
          setAnchorEl(event.currentTarget);
        }}
        wrap="nowrap"
      >
        <Grid container>
          <Grid container direction="row" item justifyContent="flex-end">
            <UserFullNameTypography variant="buttonMedium">{firstName}</UserFullNameTypography>
            <UserFullNameTypography variant="buttonMedium">
              &nbsp;
              <span>{lastName}</span>
            </UserFullNameTypography>
          </Grid>
        </Grid>
        <Grid item>
          <Avatar colorVariant="neon" label={initials} size="medium" />
        </Grid>
      </MenuRootGrid>

      {open && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          onClose={(): void => {
            setAnchorEl(null);
          }}
          open={open}
          PaperProps={{
            style: {
              marginTop: '4px',
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          {sortedMenuActions.map((action: IMenuItem): ReactElement => {
            const props: IMenuItem = {
              ...action,
              onClick: (event: MouseEvent): void => {
                if (action.onClick) {
                  action.onClick(event);
                }
                setAnchorEl(null);
              },
              schoolId,
            };
            // id is already being passed inside props
            // eslint-disable-next-line regex/invalid-warn
            return <MenuItem {...props} key={action.title} />;
          })}
        </Menu>
      )}

      <OverlayBox $open={open} />

      <ImpersonateDialog handleClose={handleImpersonateDialogClose} open={impersonateDialogOpen} />
    </>
  );
};

export default UserMenu;
