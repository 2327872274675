import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { throttle } from 'lodash';
import { createRef, ReactElement, ReactNode, useLayoutEffect, useState } from 'react';

import getIcon from '~/helpers/getIcon';

export interface IProps {
  setMenuWidth?: (value: number) => void;
}

const ChevronDown = getIcon('general.chevron.down');

/*
 * For now we are disabling and trusting the type for
 * FreeSolo and ChipComponent types of Autocomplete
 * so we are able to type correctly the Autocomplete we use (Multiple and DisableClearable)
 */
const Autocomplete = <T,>(props: AutocompleteProps<T, false, true, false> & IProps): ReactElement<unknown> | null => {
  const { componentsProps, renderInput, setMenuWidth, ...otherProps } = props;
  const [popperWidth, setPopperWidth] = useState<number | undefined>();
  const textfieldRef = createRef<HTMLDivElement>();

  useLayoutEffect(() => {
    const onResize = throttle((): void => {
      setPopperWidth(textfieldRef?.current?.offsetWidth as number);
      if (setMenuWidth) setMenuWidth(textfieldRef?.current?.offsetWidth as number);
    }, 25);

    window.addEventListener('resize', onResize);
    return (): void => {
      window.removeEventListener('resize', onResize);
    };
  }, [setMenuWidth, textfieldRef]);

  return (
    <MuiAutocomplete
      {...otherProps}
      componentsProps={{
        ...componentsProps,
        popper: {
          ...componentsProps?.popper,
          sx: {
            width: popperWidth,
            ...componentsProps?.popper?.sx,
          },
        },
      }}
      disableClearable
      popupIcon={<ChevronDown />}
      renderInput={(params): ReactNode => {
        const input = renderInput(params) as ReactElement<TextFieldProps>;
        const { id, ...inputOtherProps } = input.props;
        return <TextField {...inputOtherProps} id={id} ref={textfieldRef} />;
      }}
    />
  );
};

export default Autocomplete;
