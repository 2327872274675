import { gql } from '@apollo/client';
import { wrapRestQuery } from '@encoura/apollo-rest-utils';

import { REST } from '~/constants/rest';

import useSchoolId from '../useSchoolId';

export interface IUserPermissions {
  permissions: Array<string>;
  loading: boolean | undefined;
}

export default function useUserPermission(): IUserPermissions {
  const schoolId = useSchoolId();

  const wrappedRestQuery = wrapRestQuery<'school'>();
  const { data, loading } = wrappedRestQuery(
    gql`
      query UserPermissionQuery($hsId: ID!) {
        school(hsId: $hsId) {
          hs_id
          name
          permissions
        }
      }
    `,
    {
      endpoint: REST.GET.HIGH_SCHOOLS_BY_HSID,
      skip: !schoolId,
      variables: {
        hsId: schoolId ?? '',
      },
    },
  );

  if (data?.school?.permissions) {
    const permissions = Object.keys(data.school.permissions as Record<string, unknown>);

    return { loading, permissions };
  }
  return { loading, permissions: [] };
}
