import { ParsedUrlQuery } from 'querystring';

import { useRouter } from 'next/router';
import React from 'react';

interface IHistoryProvider {
  asPath: string;
  back: () => Promise<void>;
  nextUrl: string;
  pathname: string;
  query: ParsedUrlQuery;
}

interface IProps {
  children: React.ReactNode;
}

const HistoryProviderContext = React.createContext<IHistoryProvider | undefined>(undefined);

export const LastNavigationHistoryProvider: React.FC<IProps> = ({ children }) => {
  const { asPath, push, pathname, query, events } = useRouter();
  const historyAsPathRef = React.useRef(asPath);
  const historyPathnameRef = React.useRef(pathname);
  const historyQueryRef = React.useRef(query);
  const nextUrlRef = React.useRef('');

  // This needs to be used to return the value in case of a routeCancel
  const [historyAsPath, setHistoryAsPath] = React.useState<string>(asPath);
  const [historyPathname, setHistoryPathname] = React.useState<string>(pathname);
  const [historyQuery, setHistoryQuery] = React.useState<ParsedUrlQuery>(query);
  const [nextUrl, setNextUrl] = React.useState<string>('');

  React.useEffect(() => {
    const handleRouteChangeStart = (url: string): void => {
      historyAsPathRef.current = asPath;
      historyPathnameRef.current = pathname;
      historyQueryRef.current = query;
      nextUrlRef.current = url;
    };

    const handleRouteChangeComplete = (url: string): void => {
      setHistoryAsPath(historyAsPathRef.current);
      setHistoryPathname(historyPathnameRef.current);
      setHistoryQuery(historyQueryRef.current);
      setNextUrl(url);
    };

    events.on('routeChangeStart', handleRouteChangeStart);
    events.on('routeChangeComplete', handleRouteChangeComplete);

    /*
     * In cases where the user accesses by deep linking or using bookmarked url
     * we need to reconstruct the last url by retrieving the actual curl with
     * the last part of the url removed. Also verifies in cases where the last page was a 404
     * This happens due the next/js behaviour
     */
    if (historyAsPath === pathname || historyPathname === '/404') {
      const reconstructedUrl = asPath.slice(0, asPath.lastIndexOf('/'));
      setHistoryAsPath(reconstructedUrl);
    }

    return function cleanup(): void {
      events.off('routeChangeStart', handleRouteChangeStart);
      events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [asPath, events, historyAsPath, historyPathname, historyQuery, pathname, query]);

  const value: IHistoryProvider = React.useMemo(() => {
    async function back(): Promise<void> {
      /*
       * Using this back() function will allow us to retrieve to the last url visisted
       * saving query params and in case of deep link or bookemarked url access we return
       * to the curl with the last part o the url removed
       */
      if (historyAsPath !== pathname || historyPathname === '/404') {
        await push({
          pathname: historyPathname,
          query: historyQuery,
        });
      } else {
        const reconstructedUrl = asPath.slice(0, asPath.lastIndexOf('/'));
        await push(reconstructedUrl);
      }
    }

    return {
      asPath: historyAsPath,
      back,
      nextUrl,
      pathname: historyPathname,
      query: historyQuery,
    };
  }, [asPath, historyAsPath, historyPathname, historyQuery, nextUrl, pathname, push]);

  return <HistoryProviderContext.Provider value={value}>{children}</HistoryProviderContext.Provider>;
};

export function useLastNavigationContext(): IHistoryProvider {
  const context = React.useContext(HistoryProviderContext);
  if (context === undefined) {
    throw new Error('useLastNavigationContext must be used within a HistoryProvider');
  }
  return context;
}
