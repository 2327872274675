import { isString } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';

import NAMES from '~/constants/names';
import { HS_REGEX } from '~/constants/routes';
import localCache from '~/helpers/localCache';

export const extractSchoolIdFromPath = (path: string): string | undefined => {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const schoolIdRegex = new RegExp(HS_REGEX);
  const schoolIdMatch = schoolIdRegex.exec(path);
  const schoolId = schoolIdMatch ? schoolIdMatch[0] : undefined;

  return schoolId;
};

const checkAndParseSchoolID = (path: string): string => {
  const schoolId = extractSchoolIdFromPath(path);
  return isString(schoolId) ? schoolId : localCache.get<string>(NAMES.SCHOOL_ID) || '';
};

export const useSchoolId = (): string => {
  const router = useRouter();

  const path = router?.asPath;

  const schoolId = React.useMemo(() => checkAndParseSchoolID(path), [path]);

  return schoolId;
};

export default useSchoolId;
