import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';

import Icon from '~/components/Icon';
import { styled } from '~/helpers/styled';

export const CheckmarkIcon = styled(Icon)(() => ({
  display: 'flex',
  fill: '#00BE75',
  float: 'left',
  height: 24,
  width: 24,
}));

export const FeatureImage = styled(Grid)(({ theme }) => ({
  '& img': {
    height: 'auto',
    width: '100%',
  },
  margin: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

export const LogoImage = styled('img')(() => ({
  height: 64,
  width: 'auto',
}));

export const MainContent = styled(Grid)(({ theme }) => ({
  padding: `0 ${theme.spacing(4)}`,
}));

export const Preamble = styled(DialogContentText)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0`,
}));
