import Button, { ButtonProps } from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { styled } from '~/helpers/styled';

import IconButton from '../IconButton';

export const DialogStyled = styled(Dialog)<
  DialogProps & { $noGap: boolean } & { $dialogHeight: number } & { $screenHeight: number | null }
>(context => {
  const dialogHeight = context.$dialogHeight;
  const screenHeight = context?.$screenHeight;

  return {
    '&.MuiDialog-root': {
      height: '100vh',
    },
    '.MuiDialog-container': {
      maxHeight: `calc(100vh - 150px)`,
    },
    '.MuiDialog-paper': {
      borderRadius: '8px',
      boxShadow:
        '0px 4px 8px rgba(79, 94, 113, 0.1), 0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12)',
      gap: context.$noGap ? '0px' : '16px',
      marginTop: `max(-32px, min(32px, ${screenHeight ? screenHeight - dialogHeight - 96 : 480}px))`,
      maxWidth: '100%',
      padding: '32px 24px',
    },
  };
});

export const DialogTitleStyled = styled(DialogTitle)(() => ({
  alignItems: 'flex-start',
  display: 'flex',
  gap: '16px',
  justifyContent: 'space-between',
  padding: '0',
}));

export const DialogContentStyled = styled(DialogContent)(() => ({
  padding: '0',
}));

export const DialogActionsStyled = styled(DialogActions)(() => ({
  button: {
    textTransform: 'none',
  },
  gap: 8,
  margin: '0',
  padding: '0',
}));

export const CancelButton = styled(Button)<ButtonProps & { $noButtonLabelWrap: boolean }>(context => ({
  whiteSpace: context.$noButtonLabelWrap ? 'nowrap' : 'normal',
}));

export const ConfirmButton = styled(Button)<ButtonProps & { $noButtonLabelWrap: boolean }>(context => ({
  whiteSpace: context.$noButtonLabelWrap ? 'nowrap' : 'normal',
}));

export const CloseIconButton = styled(IconButton)(() => ({
  height: 40,
  width: 40,
}));
