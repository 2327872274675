import { AlertProps } from '@mui/material/Alert';
import { FC, ReactElement } from 'react';

import { SeverityType } from '~/components/Alert';

import { AlertStyled } from './styles';

type ISnackbarAlertProps = Omit<AlertProps, 'severity'> & {
  severity: SeverityType | 'standard';
};

const SnackbarAlert: FC<ISnackbarAlertProps> = ({ children, onClose, style, severity }: ISnackbarAlertProps): ReactElement<unknown> => {
  const isStandard = severity === 'standard';
  const sev: SeverityType = isStandard ? 'info' : severity;

  return <AlertStyled message={children} onClose={onClose} severity={sev} style={style} variant={isStandard ? 'standard' : 'outlined'} />;
};

export default SnackbarAlert;
