import { ApplicationModule, ReportModule } from '~/types';

export const MODULES: Record<string, ApplicationModule> = {
  APPLICATION_MANAGEMENT: 'applicationManagement',
  CREDENTIAL_SUBMISSION: 'credentialSubmission',
  E4E_REPORT_ADMIN: 'e4eReportAdmin',
  HIGH_SCHOOL_ADMIN: 'highSchoolAdmin',
  MENTORING: 'mentoring',
};

export const REPORT_MODULES: Record<string, ReportModule> = {
  ACTEHighSchoolTeacherReport: 'reportACTE',
  ACTFLHighSchoolTeacherReport: 'reportACTFL',
  ARTHighSchoolTeacherReport: 'reportART',
  COFJROTCHighSchoolTeacherReport: 'reportCOF',
  DECAHighSchoolTeacherReport: 'reportDECA',
  DistrictPostsecondaryReport: 'reportSUPERINTENDENT',
  FBLAHighSchoolTeacherReport: 'reportFBLA',
  FCCLAHighSchoolTeacherReport: 'reportFCCLA',
  MAAHighSchoolTeacherReport: 'reportMAA',
  MCSHighSchoolTeacherReport: 'reportMCS',
  MOHighSchoolTeacherReport: 'reportMCO',
  NABSEHighSchoolTeacherReport: 'reportNABSE',
  NAFMEHighSchoolTeacherReport: 'reportNAFME',
  NCSSHighSchoolTeacherReport: 'reportNCSS',
  SHAPEHighSchoolTeacherReport: 'reportSHAPE',
  STEMHighSchoolTeacherReport: 'reportSTEM',
  SuperintendentReport: 'reportSUPERINTENDENT',
};

export default MODULES;
