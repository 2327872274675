import MuiLink, { LinkProps } from '@mui/material/Link';
import NextLink from 'next/link';
import { FC, MouseEventHandler, ReactElement, ReactNode, Ref } from 'react';

export interface ILinkProps extends LinkProps {
  children?: ReactNode;
  enabled?: boolean;
  externalLink?: boolean;
  linkRef?: Ref<HTMLAnchorElement>;
  onClick?: MouseEventHandler;
  passHref?: boolean;
}

const Link: FC<ILinkProps> = ({ children, externalLink, href, linkRef, onClick, passHref, ...otherProps }: ILinkProps): ReactElement<unknown> => {
  return (onClick !== undefined && !href) || externalLink ? (
    <MuiLink href={href} onClick={onClick} {...otherProps} ref={linkRef} target="_blank">
      {children}
    </MuiLink>
  ) : (
    <NextLink href={href ?? ''} legacyBehavior passHref={passHref}>
      <MuiLink onClick={onClick} {...otherProps}>
        {children}
      </MuiLink>
    </NextLink>
  );
};

export default Link;
