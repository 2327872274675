import { AlertContext } from '@actinc/dls/context/AlertContext';
import { useCallback, useContext } from 'react';

export const useAlert: () => {
  showErrorAlert: (message: string) => void;
  showInfoAlert: (message: string) => void;
  showStandardAlert: (message: string) => void;
  showSuccessAlert: (message: string) => void;
  showWarningAlert: (message: string) => void;
} = () => {
  const { actions } = useContext(AlertContext);

  const showErrorAlert = useCallback(
    (message: string) => actions.addAlert({ message, options: { variant: 'error' } }),
    [actions],
  );
  const showInfoAlert = useCallback(
    (message: string) => actions.addAlert({ message, options: { variant: 'info' } }),
    [actions],
  );
  const showStandardAlert = useCallback(
    (message: string) => actions.addAlert({ message, options: { variant: 'default' } }),
    [actions],
  );
  const showSuccessAlert = useCallback(
    (message: string) => actions.addAlert({ message, options: { variant: 'success' } }),
    [actions],
  );
  const showWarningAlert = useCallback(
    (message: string) => actions.addAlert({ message, options: { variant: 'warning' } }),
    [actions],
  );

  return { showErrorAlert, showInfoAlert, showStandardAlert, showSuccessAlert, showWarningAlert };
};

export default useAlert;
