import React from 'react';

import SchoolSearch from '~/components/SchoolSearch/index';
import NAMES from '~/constants/names';
import { EducatorSearchText, LDAPSearchText } from '~/constants/schoolSearch';
import USER_TYPES from '~/constants/userTypes';
import useActiveUser from '~/hooks/useActiveUser';
import useLocalStorage from '~/hooks/useLocalStorage';

import DialogContainer from '../DialogContainer';

interface IProps {
  handleClose: () => void;
  open: boolean;
}

const SchoolSelectionDialog: React.FC<IProps> = (props): React.ReactElement => {
  const { handleClose, open } = props;
  const [modalOpen, setModalOpen] = React.useState<boolean>(open);

  React.useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const currentUser = useActiveUser();

  useLocalStorage(NAMES.IMPERSONATING, null);

  const isUserLdap = currentUser?.type === USER_TYPES.LDAP;

  return (
    <DialogContainer
      hideButtons
      onClose={(): void => handleClose()}
      open={modalOpen}
      size="medium"
      title={isUserLdap ? LDAPSearchText.title : EducatorSearchText.title}
    >
      <SchoolSearch closeDialog={handleClose} />
    </DialogContainer>
  );
};

export default SchoolSelectionDialog;
