import { gql } from '@apollo/client';
import { wrapRestQuery } from '@encoura/apollo-rest-utils';

import REST from '~/constants/rest';
import { UMSUserAttributes } from '~/constants/umsAttributes';
import USER_TYPES from '~/constants/userTypes';
import useActiveUser from '~/hooks/useActiveUser';

export const USER_ATTRIBUTES_QUERY = gql`
  query UserAttributesQuery($uid: String!) {
    userProfile(uid: $uid) {
      attributes
      uid
    }
  }
`;

const useActiveUserAttributes = (): UMSUserAttributes | null => {
  const activeUser = useActiveUser();

  if (!activeUser) return null;

  const { data } = wrapRestQuery<'userProfile'>()(USER_ATTRIBUTES_QUERY, {
    endpoint: REST.GET.USERS_BY_UID,
    skip: activeUser.type === USER_TYPES.LDAP,
    variables: {
      uid: activeUser.uid,
    },
  });

  const attributesList = data?.userProfile?.attributes;
  if (!attributesList) return null;

  const attributes: Record<string, unknown> = {};
  attributesList.forEach(item => {
    attributes[item.key] = item.value;
  });

  return attributes;
};

export default useActiveUserAttributes;
