// eslint-disable-next-line regex/invalid-error
import { ComponentsProps } from '@mui/material';

const props: ComponentsProps = {
  MuiButton: {
    disableRipple: true,
  },
  MuiButtonBase: {
    disableRipple: true,
  },
};

export default props;
