import ENV from './env';

export default {
  ACTIVATION_SUPPORT_EMAIL: 'educators@myoptions.org',
  APP_ENCOURAGE_ME: 'https://app.encourageme.com/',
  CONNECTING_WITH_STUDENTS_GUIDE:
    'https://equityinlearning.act.org/wp-content/uploads/2020/08/College-Match-and-Fit-Toolkit-Navigation_Aug2020.pdf',
  E4S_DOWNLOAD_LINK: 'https://encourageme.com/download/',
  E4S_EDUCATOR_INVITE_LINK: (inviteCode: string): string => `${ENV.E4S_INVITE_URL as string}${inviteCode}`,
  E4S_WEB_URL_COLLEGE_LINK: (fice: string): string => `${ENV.E4S_WEB_URL as string}/explore/my-colleges/${fice}`,
  ENCOURAGE_ME: 'https://encourageme.com/encourage-for-educators/',
  ENCOURAGE_ME_HOME: 'https://encourageme.com/',
  MAIL_TO_ACTIVATION_SUPPORT: 'mailto:educators@myoptions.org',
  MAIL_TO_SUPPORT: 'mailto:encouragesupport@myoptions.org',
  MY_OPTIONS_TERMS_OF_USE: 'https://encourageme.com/terms-of-use/educator/',
  OPTOUT: 'https://educators.mycollegeoptions.org/',
  PRIVACY_POLICY: 'https://encourageme.com/privacy-policy/',
  REQUEST_ASSISTANCE: 'https://encouragehelp.myoptions.org/hc/en-us/requests/new',
  TERMS_OF_USE: 'https://encourageme.com/terms-of-use/educator/',
};
