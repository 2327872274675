import Box from '@mui/material/Box';

import { styled } from '~/helpers/styled';

// eslint-disable-next-line import/prefer-default-export
export const SpinnerContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  width: '100%',
}));
