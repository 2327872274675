import React from 'react';

interface IWindowDimensions {
  windowHeight: number | null;
  windowWidth: number | null;
}

export default function useWindowDimensions(): IWindowDimensions | null {
  const hasWindow = typeof window !== 'undefined';
  const [windowDimensions, setWindowDimensions] = React.useState<IWindowDimensions | null>(null);

  function getWindowDimensions(): IWindowDimensions {
    const windowWidth = hasWindow ? window.innerWidth : null;
    const windowHeight = hasWindow ? window.innerHeight : null;
    return {
      windowHeight,
      windowWidth,
    };
  }

  function handleResize(): void {
    setWindowDimensions(getWindowDimensions());
  }

  React.useEffect(() => {
    if (hasWindow) {
      window.addEventListener('resize', handleResize);
      return (): void => window.removeEventListener('resize', handleResize);
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWindow]);

  return windowDimensions;
}
