/* eslint-disable no-restricted-imports */
/* Search for icons here: https://pictogrammers.com/library/mdi/ */

import AccountMultipleOutline from '@actinc/dls/icons/AccountMultipleOutline';
import AccountOutline from '@actinc/dls/icons/AccountOutline';
import AccountPlusOutline from '@actinc/dls/icons/AccountPlusOutline';
import AccountSupervisorCircleOutline from '@actinc/dls/icons/AccountSupervisorCircleOutline';
import AlertCircle from '@actinc/dls/icons/AlertCircle';
import AlertCircleOutline from '@actinc/dls/icons/AlertCircleOutline';
import AlertOutline from '@actinc/dls/icons/AlertOutline';
import ArrowUp from '@actinc/dls/icons/ArrowUp';
import BankOutline from '@actinc/dls/icons/BankOutline';
import Bug from '@actinc/dls/icons/Bug';
import BullseyeArrow from '@actinc/dls/icons/BullseyeArrow';
import CalendarBlankOutline from '@actinc/dls/icons/CalendarBlankOutline';
import ChartBoxOutline from '@actinc/dls/icons/ChartBoxOutline';
import CheckCircleOutline from '@actinc/dls/icons/CheckCircleOutline';
import ChevronDoubleRight from '@actinc/dls/icons/ChevronDoubleRight';
import ChevronDown from '@actinc/dls/icons/ChevronDown';
import ChevronLeft from '@actinc/dls/icons/ChevronLeft';
import ChevronRight from '@actinc/dls/icons/ChevronRight';
import ChevronUp from '@actinc/dls/icons/ChevronUp';
import ClipboardTextOutline from '@actinc/dls/icons/ClipboardTextOutline';
import ClockTimeFourOutline from '@actinc/dls/icons/ClockTimeFourOutline';
import Close from '@actinc/dls/icons/Close';
import CloseCircle from '@actinc/dls/icons/CloseCircle';
import CloseCircleOutline from '@actinc/dls/icons/CloseCircleOutline';
import CurrencyUsd from '@actinc/dls/icons/CurrencyUsd';
import DotsVertical from '@actinc/dls/icons/DotsVertical';
import Export from '@actinc/dls/icons/Export';
import EyeOffOutline from '@actinc/dls/icons/EyeOffOutline';
import EyeOutline from '@actinc/dls/icons/EyeOutline';
import FileOutline from '@actinc/dls/icons/FileOutline';
import FlagOutline from '@actinc/dls/icons/FlagOutline';
import Fullscreen from '@actinc/dls/icons/Fullscreen';
import FullscreenExit from '@actinc/dls/icons/FullscreenExit';
import Heart from '@actinc/dls/icons/Heart';
import Help from '@actinc/dls/icons/Help';
import Information from '@actinc/dls/icons/Information';
import InformationOutline from '@actinc/dls/icons/InformationOutline';
import Magnify from '@actinc/dls/icons/Magnify';
import MonitorCellphone from '@actinc/dls/icons/MonitorCellphone';
import OpenInNew from '@actinc/dls/icons/OpenInNew';
import PencilOutline from '@actinc/dls/icons/PencilOutline';
import ReplyOutline from '@actinc/dls/icons/ReplyOutline';
import SchoolOutline from '@actinc/dls/icons/SchoolOutline';
import SwapVertical from '@actinc/dls/icons/SwapVertical';
import ViewDashboardOutline from '@actinc/dls/icons/ViewDashboardOutline';
import Web from '@actinc/dls/icons/Web';
import { ConstructionOutlined, DashboardOutlined, DeleteOutline, DescriptionOutlined, KeyboardTab } from '@mui/icons-material';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import GroupsOutlined from '@mui/icons-material/GroupsOutlined';
import SmartDisplayOutlined from '@mui/icons-material/SmartDisplayOutlined';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import IconCareer from 'encourage-ecosystem-lib/components/IconCareer';
import IconCollege from 'encourage-ecosystem-lib/components/IconCollege';
import IconMajor from 'encourage-ecosystem-lib/components/IconMajor';
import IconMatched from 'encourage-ecosystem-lib/components/IconMatched';
import IconPDF from 'encourage-ecosystem-lib/components/IconPDF';
import IconScholarship from 'encourage-ecosystem-lib/components/IconScholarship';

export const iconKeys = [
  'actions.info',
  'actions.openInNew',
  'actions.search',
  'clock.time.four.outlined',
  'general.account.balance',
  'general.account.multiple.outlined',
  'general.AccountSupervisorCircleOutline',
  'general.alert.circle',
  'general.alert.circleOutline',
  'general.alert.flagOutline',
  'general.alert.informationOutline',
  'general.alert.outline',
  'general.applications',
  'general.arrow.up',
  'general.bullseye.arrow',
  'general.calendar.blank',
  'general.cards',
  'general.careers',
  'general.checkmark.circle.outlined',
  'general.chevron.down',
  'general.chevron.left',
  'general.chevron.right',
  'general.chevron.up',
  'general.close.circle.outline',
  'general.close.circle',
  'general.close',
  'general.college',
  'general.construction',
  'general.dashboard',
  'general.debug',
  'general.delete.outline',
  'general.dots.vertical',
  'general.doubleArrow.right',
  'general.export',
  'general.eye.close',
  'general.eye.open',
  'general.favorite',
  'general.file.outline',
  'general.fileDocument',
  'general.fullScreen',
  'general.fullScreenExit',
  'general.keyboard.tab',
  'general.major',
  'general.monetizationOutlined',
  'general.monitor.cellphone',
  'general.pdfFile',
  'general.pencilOutline',
  'general.personAddOutlined',
  'general.play',
  'general.replyOutline',
  'general.report',
  'general.scholarship',
  'general.schoolOutlined',
  'general.smartDisplay',
  'general.student',
  'general.students',
  'general.swap.vertical',
  'general.web',
  'general.viewDashboard.outline',
] as const;

export type IconSelector = (typeof iconKeys)[number];

// For searchable list of icons: https://materialdesignicons.com/ though the names may be slightly different

const iconMap: Map<IconSelector, typeof SvgIcon> = new Map([
  ['actions.info', Information],
  ['actions.openInNew', OpenInNew],
  ['actions.search', Magnify],
  ['clock.time.four.outlined', ClockTimeFourOutline],
  ['general.account.balance', BankOutline],
  ['general.account.multiple.outlined', AccountMultipleOutline],
  ['general.AccountSupervisorCircleOutline', AccountSupervisorCircleOutline],
  ['general.alert.circle', AlertCircle],
  ['general.alert.circleOutline', AlertCircleOutline],
  ['general.alert.flagOutline', FlagOutline],
  ['general.alert.informationOutline', InformationOutline],
  ['general.alert.outline', AlertOutline],
  ['general.applications', ClipboardTextOutline],
  ['general.arrow.up', ArrowUp],
  ['general.bullseye.arrow', BullseyeArrow],
  ['general.calendar.blank', CalendarBlankOutline],
  ['general.checkmark.circle.outlined', CheckCircleOutline],
  ['general.chevron.down', ChevronDown],
  ['general.chevron.left', ChevronLeft],
  ['general.chevron.right', ChevronRight],
  ['general.chevron.up', ChevronUp],
  ['general.close.circle.outline', CloseCircleOutline],
  ['general.close.circle', CloseCircle],
  ['general.close', Close],
  ['general.construction', ConstructionOutlined],
  ['general.dashboard', DashboardOutlined],
  ['general.debug', Bug],
  ['general.delete.outline', DeleteOutline],
  ['general.dots.vertical', DotsVertical],
  ['general.doubleArrow.right', ChevronDoubleRight],
  ['general.export', Export],
  ['general.eye.close', EyeOffOutline],
  ['general.eye.open', EyeOutline],
  ['general.favorite', Heart],
  ['general.file.outline', FileOutline],
  ['general.fileDocument', DescriptionOutlined],
  ['general.fullScreen', Fullscreen],
  ['general.fullScreenExit', FullscreenExit],
  ['general.keyboard.tab', KeyboardTab],
  ['general.monetizationOutlined', CurrencyUsd],
  ['general.monitor.cellphone', MonitorCellphone],
  ['general.pencilOutline', PencilOutline],
  ['general.personAddOutlined', AccountPlusOutline],
  ['general.play', SmartDisplayOutlined],
  ['general.replyOutline', ReplyOutline],
  ['general.report', ChartBoxOutline],
  ['general.schoolOutlined', SchoolOutline],
  ['general.smartDisplay', DynamicFeedOutlinedIcon],
  ['general.student', AccountOutline],
  ['general.students', GroupsOutlined],
  ['general.swap.vertical', SwapVertical],
  ['general.web', Web],
  ['general.viewDashboard.outline', ViewDashboardOutline],
]);

const customIconMap: Map<IconSelector, React.FC<SvgIconProps>> = new Map([
  ['general.cards', IconMatched],
  ['general.careers', IconCareer],
  ['general.college', IconCollege],
  ['general.major', IconMajor],
  ['general.pdfFile', IconPDF],
  ['general.scholarship', IconScholarship],
]);

export default function getIcon(selector: IconSelector): typeof SvgIcon {
  return (iconMap.get(selector) ?? customIconMap.get(selector) ?? Help) as typeof SvgIcon;
}
