import { styled } from '~/helpers/styled';

import Alert from '../Alert';

// eslint-disable-next-line import/prefer-default-export
export const AlertStyled = styled(Alert)(({ theme }) => ({
  '&.MuiAlert-outlinedError': {
    borderColor: theme.palette.utility.error[700],
    borderLeft: `4px solid ${theme.palette.utility.error[700]}`,
  },
  '&.MuiAlert-outlinedInfo': {
    borderColor: `${theme.palette.branding.sapphire[500]}`,
    borderLeft: `4px solid ${theme.palette.branding.sapphire[500]}`,
    svg: {
      fill: `${theme.palette.branding.sapphire[500]}`,
    },
  },
  '&.MuiAlert-outlinedSuccess': {
    borderLeft: `4px solid ${theme.palette.utility.success[500]}`,
    svg: {
      fill: `${theme.palette.utility.success[500]}`,
    },
  },
  '&.MuiAlert-outlinedWarning': {
    borderLeft: `4px solid ${theme.palette.utility.warning[600]}`,
  },
  '&.MuiAlert-root': {
    borderRadius: '2px 4px 4px 2px',
    boxShadow: theme.shadows[8],
    color: theme.palette.text.overLight.high,
    height: '66px',
    maxWidth: '600px',
    minWidth: '250px',
    padding: '21px 8px 21px 16px',
  },
  '&.MuiAlert-standard': {
    '.MuiAlert-icon': {
      display: 'flex',
      svg: {
        fill: `rgba(0, 0, 0, 0.6)`,
      },
    },
    background: 'white',
    borderColor: `${theme.palette.branding.mist[500]}`,
    borderLeft: `4px solid ${theme.palette.branding.mist[500]}`,
  },
}));
