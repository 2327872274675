import { asError } from '../errors';
import sentryGraphqlRequestError from '../SentryErrors/sentryGraphqlRequestError';

const localCache = {
  get: <T = string>(cacheKey: string): T | undefined => {
    const value = global?.window?.localStorage.getItem(cacheKey);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return value ? (JSON.parse(value).value as T) : undefined;
    } catch (unkErr: unknown) {
      const error = asError(unkErr);
      sentryGraphqlRequestError({ error, level: 'error', variables: {} });
      return undefined;
    }
  },
  set: <T>(cacheKey: string, value: T | undefined): void => {
    const currentValue = global?.window?.localStorage.getItem(cacheKey);
    if (value === undefined) {
      global?.window?.localStorage.removeItem(cacheKey);
    } else {
      global?.window?.localStorage.setItem(cacheKey, JSON.stringify({ value }));
    }
    if ((value as unknown) !== currentValue) {
      global?.window?.dispatchEvent(new StorageEvent('storage'));
    }
  },
};

export default localCache;
