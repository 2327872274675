import { Operation } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { isError } from 'lodash';

const sentryApolloClientError = (err: unknown, operation: Operation): void => {
  Sentry?.captureException(err, {
    extra: {
      context: operation?.getContext(),
      error: isError(err) && err?.message,
      operationName: operation?.operationName,
      variables: operation?.variables,
    },
    tags: {
      feature: 'ApolloClient',
    },
  });
};

export default sentryApolloClientError;
