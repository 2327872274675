import { ApolloError, ApolloQueryResult, QueryResult } from '@apollo/client';

export enum FiniteState {
  Idle,
  Loading,
  Error,
  Ready,
}

export interface IIdleQueryState<T> {
  data: null;
  error: null;
  loading: false;
  state: FiniteState.Idle;
  refetch: (variables?: Partial<unknown> | undefined) => Promise<ApolloQueryResult<T>>;
}

export interface ILoadingQueryState<T> {
  data: null;
  error: null;
  loading: true;
  state: FiniteState.Loading;
  refetch: (variables?: Partial<unknown> | undefined) => Promise<ApolloQueryResult<T>>;
}

export interface IErrorQueryState<T> {
  data: null;
  error: ApolloError;
  loading: false;
  state: FiniteState.Error;
  refetch: (variables?: Partial<unknown> | undefined) => Promise<ApolloQueryResult<T>>;
}

export interface IReadyQueryState<T> {
  data: T;
  error: null;
  loading: false;
  state: FiniteState.Ready;
  refetch: (variables?: Partial<unknown> | undefined) => Promise<ApolloQueryResult<T>>;
}

export type FiniteQueryState<T> =
  | IIdleQueryState<T>
  | ILoadingQueryState<T>
  | IErrorQueryState<T>
  | IReadyQueryState<T>;

/**
 * Reduces a query result state to a finite state descriptor.
 *
 * This is useful as it allows TypeScript to make assertions about the state when
 * doing verifications on its fields, and thus narrowing the type of the state to
 * one of its known finite states.
 *
 * It also reduces the number of tests necessary, since only a few combination of
 * values are possible, determined by the possible finite states.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toFiniteState<T>(result: QueryResult<T, any>): FiniteQueryState<T> {
  const { data, error, loading, refetch } = result;

  if (loading) {
    return {
      data: null,
      error: null,
      loading,
      refetch,
      state: FiniteState.Loading,
    };
  }

  if (error) {
    return {
      data: null,
      error,
      loading: false,
      refetch,
      state: FiniteState.Error,
    };
  }

  if (!data) {
    return {
      data: null,
      error: null,
      loading: false,
      refetch,
      state: FiniteState.Idle,
    };
  }

  return {
    data,
    error: null,
    loading: false,
    refetch,
    state: FiniteState.Ready,
  };
}
