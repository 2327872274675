import * as Sentry from '@sentry/nextjs';
import { isError } from 'lodash';

const sentryRoutingError = ({
  error,
  to,
  message,
  query,
}: {
  error: unknown;
  to: string;
  message?: string;
  query?: unknown;
}): void => {
  Sentry?.captureException(error, {
    extra: {
      error: JSON.stringify(isError(error) && error.message),
      message: JSON.stringify(message),
      query: JSON.stringify(query),
      to: JSON.stringify(to),
    },
    tags: {
      feature: 'Routing',
    },
  });
};

export default sentryRoutingError;
