import { AutocompleteProps } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { isString } from 'lodash';
import { ChangeEvent, FC, ReactElement, ReactNode, useState } from 'react';

import Autocomplete from '~/components/Autocomplete';
import Search, { IOption, OptionType } from '~/components/Search';
import { ITextStrings } from '~/constants/schoolSearch';
import TAG_IDS from '~/constants/tagIds';
import { getItemsMaxLenghtByKey, getResultsNumber, shouldUpdateGridBreakpoints } from '~/helpers/schoolSearch';
import { UserRoles } from '~/types';

import SchoolLink, { ISchool } from '../SchoolLink';

import { StyledContentGrid } from './styles';

interface IProps {
  searchText: string;
  items: ISchool[];
  state: 'idle' | 'loading' | 'loaded';
  texts: ITextStrings;
  closeDialog?: () => void;
  onChangeSearchText: (newText: string) => void;
  userIsLdap?: boolean;
}

const SchoolSearch: FC<IProps> = (props): ReactElement<unknown> => {
  const { searchText, items, state, texts, closeDialog, onChangeSearchText, userIsLdap = false } = props;
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState<boolean>(false);
  const [menuWidth, setMenuWidth] = useState<number>();
  const [searchValue, setSearchValue] = useState<IOption | string>('');

  const maxItemSize = getItemsMaxLenghtByKey(items, 'name');
  const shouldWrapLabel = shouldUpdateGridBreakpoints(menuWidth, maxItemSize);

  const searchProps: Omit<AutocompleteProps<OptionType, false, true, false>, 'renderInput'> = {
    filterOptions: (options, filterState): typeof options => {
      const filteredOptions = options.filter(option => {
        return (
          !isString(option) &&
          (option.label.toLowerCase().includes(filterState.inputValue.toLowerCase()) ||
            (option.label2 && option.label2.toLowerCase().includes(filterState.inputValue.toLowerCase())))
        );
      });
      const numberOfResults = filteredOptions.length;
      return [
        {
          id: TAG_IDS.SCHOOL_SEARCH.SCHOOL_SEARCH_AUTOCOMPLETE_MENU_HEADER,
          label: state === 'loading' ? 'Searching...' : getResultsNumber(numberOfResults),
          label2: undefined,
          schoolCity: undefined,
          schoolId: '',
          schoolState: undefined,
        },
        ...filteredOptions,
      ];
    },
    fullWidth: true,
    getOptionDisabled: (option): boolean => !isString(option) && option.id.startsWith(TAG_IDS.SCHOOL_SEARCH.SCHOOL_SEARCH_AUTOCOMPLETE_MENU_HEADER),
    id: TAG_IDS.SCHOOL_SEARCH.SECONDARY_SCHOOL_AUTOCOMPLETE_INPUT,
    loading: state === 'loading',
    noOptionsText: state === 'loading' ? 'Searching...' : 'No results found',
    openOnFocus: true,
    options: items.map(option => ({
      id: TAG_IDS.SCHOOL_SEARCH.SEARCH_SCHOOL_AUTOCOMPLETE_OPTION(option.hs_id),
      label: option.name,
      label2: option.role,
      schoolCity: option.city ?? undefined,
      schoolId: option.hs_id,
      schoolState: option.state ?? undefined,
    })),
    renderOption: (optionProps, option): ReactNode => {
      if (isString(option)) return <li>{option}</li>;
      return option.schoolId ? (
        <SchoolLink
          closeDialog={closeDialog}
          key={`${option.id}-link`}
          linkProps={optionProps}
          maxItemSize={maxItemSize}
          menuWidth={menuWidth}
          school={{ city: option.schoolCity, hs_id: option.schoolId, name: option.label, role: option.label2 as UserRoles, state: option.schoolState }}
          shouldWrapLabel={shouldWrapLabel}
          showCityAndState={!!userIsLdap}
        />
      ) : (
        <li {...optionProps}>
          <Grid container justifyContent="space-between">
            <Grid item>{option.label}</Grid>
          </Grid>
        </li>
      );
    },
    size: 'large',
  };

  return (
    <Grid container gap={7}>
      {!closeDialog && (
        <Grid item xs={12}>
          <Typography component="span" variant="h4">
            {texts.title}
          </Typography>
        </Grid>
      )}
      <StyledContentGrid isDialog={!!closeDialog} item xs={12}>
        {!userIsLdap ? (
          <Autocomplete
            {...searchProps}
            renderInput={(params): ReactNode => (
              // eslint-disable-next-line regex/invalid-warn
              <TextField
                {...params}
                fullWidth
                id={TAG_IDS.SCHOOL_SEARCH.SCHOOL_SEARCH_AUTOCOMPLETE_TEXTFIELD}
                InputProps={{
                  ...params.InputProps,
                  placeholder: '',
                }}
                label={!isAutocompleteOpen ? texts.inputPlaceholder : texts.inputFocusedPlaceholder}
                onBlur={(): void => setIsAutocompleteOpen(false)}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  onChangeSearchText(e.target.value);
                }}
                onFocus={(): void => setIsAutocompleteOpen(true)}
                value={searchText}
                variant="outlined"
              />
            )}
            setMenuWidth={(width: number): void => setMenuWidth(width)}
            size="large"
          />
        ) : (
          <Search
            {...searchProps}
            setMenuWidth={(width: number): void => setMenuWidth(width)}
            setValue={(newValue: OptionType | null): void => setSearchValue(newValue ?? '')}
            size="medium"
            sx={{ width: 'auto' }}
            textFieldProps={{
              label: !isAutocompleteOpen ? texts.inputPlaceholder : texts.inputFocusedPlaceholder,
              onChange: (e: ChangeEvent<HTMLInputElement>): void => {
                onChangeSearchText(e.target.value);
              },
            }}
            texts={texts}
            value={searchValue}
          />
        )}
      </StyledContentGrid>
    </Grid>
  );
};

export default SchoolSearch;
