import { gql } from '@apollo/client';
import { wrapRestQuery, NamedGQLResult } from '@encoura/apollo-rest-utils';

import { REST } from '~/constants/rest';
import { toFiniteState, FiniteQueryState } from '~/helpers/apollo/finiteQueryState';
import useSchoolId from '~/hooks/useSchoolId';
import { SecondarySchool } from '~/types';

export const GET_SECONDARY_SCHOOL_INFO = gql`
  query SchoolInfoQuery($hsId: ID!) {
    school(hsId: $hsId) {
      hs_id
      name
      city
      district
      state
    }
  }
`;

type QueryType = NamedGQLResult<'school', Pick<SecondarySchool, 'hs_id' | 'name' | 'city' | 'district' | 'state'>>;

export const useSchoolInfo = (): FiniteQueryState<QueryType> => {
  const schoolId = useSchoolId();
  const queryState = wrapRestQuery<'school'>()(GET_SECONDARY_SCHOOL_INFO, {
    endpoint: REST.GET.HIGH_SCHOOLS_BY_HSID,
    skip: !schoolId || schoolId === 'Mentor',
    variables: {
      hsId: schoolId ?? '',
    },
  });

  return toFiniteState(queryState);
};

export default useSchoolInfo;
