import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { styled } from '~/helpers/styled';

export const INPUT_WIDTH = 568;

export const FormLabel = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
}));

export const GridItem = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(2),
}));
