// eslint-disable-next-line no-shadow
export enum Role {
  PRINCIPAL = 'principal',
  TEACHER = 'teacher',
  COUNSELOR = 'counselor',
  COACH = 'coach',
  TEACHING_ASSISTANT = 'teaching_assistant',
  SUPERINTENDENT = 'superintendent',
  OTHER = 'other',
}

export const roleLabelMap: Record<Role, string> = {
  [Role.PRINCIPAL]: 'Principal',
  [Role.TEACHER]: 'Teacher',
  [Role.COUNSELOR]: 'Counselor',
  [Role.COACH]: 'Coach',
  [Role.TEACHING_ASSISTANT]: 'Teaching Assistant',
  [Role.SUPERINTENDENT]: 'Superintendent',
  [Role.OTHER]: 'Other',
};

export const selectRoleLabelMap = Object.entries(roleLabelMap).map(entry => ({ label: entry[1], value: entry[0] }));

export const USER_ROLES = [
  {
    label: roleLabelMap[Role.COACH],
    value: Role.COACH,
  },
  {
    label: roleLabelMap[Role.COUNSELOR],
    value: Role.COUNSELOR,
  },
  {
    label: roleLabelMap[Role.PRINCIPAL],
    value: Role.PRINCIPAL,
  },
  {
    label: roleLabelMap[Role.SUPERINTENDENT],
    value: Role.SUPERINTENDENT,
  },
  {
    label: roleLabelMap[Role.TEACHER],
    value: Role.TEACHER,
  },
  {
    label: roleLabelMap[Role.TEACHING_ASSISTANT],
    value: Role.TEACHING_ASSISTANT,
  },
  {
    label: roleLabelMap[Role.OTHER],
    value: Role.OTHER,
  },
];
