import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { isEqual, sortBy } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';

import { EducatorSearchText } from '~/constants/schoolSearch';
import { asError } from '~/helpers/errors';
import sentryGraphqlRequestError from '~/helpers/SentryErrors/sentryGraphqlRequestError';
import useActiveUser from '~/hooks/useActiveUser';
import useDetailedPermissions from '~/hooks/useDetailedPermissions';
import useListSearch from '~/hooks/useListSearch';
import { IDetailedPermissions, UserRoles } from '~/types/users';

import SchoolSearch from '../SchoolSearch';

interface IProps {
  closeDialog?: () => void;
}

const UserSchoolSearch: FC<IProps> = props => {
  const { closeDialog } = props;
  const activeUser = useActiveUser();
  const { data, refetch } = useDetailedPermissions();

  const loading = useRef(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [fetchedData, setFetchedData] = useState<IDetailedPermissions | null>(null);

  useEffect(() => {
    if (!isEqual(data, fetchedData)) {
      setFetchedData(data);
    }
    // We disable the lint rule below since it produces an infinite loop
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect((): void => {
    refetch().catch((unknownError: unknown) =>
      sentryGraphqlRequestError({
        error: asError(unknownError),
        level: 'error',
        variables: {},
      }),
    ); // eslint-disable-line promise/prefer-await-to-then
    // We disable the lint rule below since it produces an infinite loop
  }, [activeUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchedSchools = fetchedData?.detailedPermissions?.map(org => ({
    city: org.organization?.city as string,
    hs_id: org.organization?.externalId as string,
    name: org.organization?.name as string,
    role: org.organization?.role as UserRoles,
    state: org.organization?.state as string,
  }));

  const resultItems = useListSearch({
    itemIdentity: item => [item.name, item.role].join(' '),
    list: sortBy(fetchedSchools, ['name']),
    query: searchQuery,
  });

  loading.current = (fetchedData?.detailedPermissions.length || 0) === 0;

  return loading.current ? (
    <Grid item textAlign="center" xs={12}>
      <CircularProgress />
    </Grid>
  ) : (
    <SchoolSearch
      closeDialog={closeDialog}
      items={resultItems}
      onChangeSearchText={setSearchQuery}
      searchText={searchQuery}
      state="loaded"
      texts={EducatorSearchText}
    />
  );
};

export default UserSchoolSearch;
