import React from 'react';

import EducationPartnerDialog from '~/components/EducationPartnerDialog';
import TAG_IDS from '~/constants/tagIds';
import { EducationPartnerSignalContext } from '~/providers/EducationPartnerSignal';

const EducationPartnerDialogHandler: React.FC = () => {
  const EducationPartnerSignal = React.useContext(EducationPartnerSignalContext);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [openCheck, setOpenCheck] = React.useState(false);

  React.useEffect(() => {
    setOpenCheck(false);
  }, [EducationPartnerSignal.requested]);

  React.useEffect(() => {
    if (openCheck) return;
    if (!EducationPartnerSignal.requested) return;

    setDialogOpen(true);
    setOpenCheck(true);
    EducationPartnerSignal.clearRequest();
  }, [EducationPartnerSignal, openCheck]);

  const handleClose = (): void => {
    setDialogOpen(false);
  };

  return <EducationPartnerDialog closeDialog={handleClose} id={TAG_IDS.DIALOGS.EDUCATION_PARTNER.MODAL} open={dialogOpen} />;
};

export default EducationPartnerDialogHandler;
