import { isString } from 'lodash';

export const getResultsNumber = (numberOfResults: number): string => {
  if (numberOfResults === 0) return 'No results found';
  return `${numberOfResults} result${numberOfResults > 1 ? 's' : ''} found`;
};

/**
 * Functions responsible to fetch the school name with the larger number of characters
 * @param array : Array containing the school Data
 * @returns : lenght of the larger school name or undefined
 */
export const getItemsMaxLenghtByKey = <T>(array: T[], key: keyof T): number | undefined => {
  if (array.length > 0) {
    const items = array.map((element: T) => {
      if (isString(element[key])) return (element[key] as string).length;
      return 0;
    });
    return Math.max.apply(null, items);
  }
  return undefined;
};

/**
 * Functions responsible to validate the breakpoint for menu items when the menu width starts to shrink and
 * we should change the grid behavior (See Student Search Component)
 * @param menuWidth : number or undefined - Menu Popper width
 * @param maxStringLength : number or undefined - Max string lenght that we'll use to validate the shrink behavior
 * @returns : boolean
 */
export const shouldUpdateGridBreakpoints = (
  menuWidth: number | undefined,
  maxStringLength: number | undefined,
): boolean => {
  return !!(menuWidth && maxStringLength && menuWidth / maxStringLength < 9);
};
