import { gql } from '@apollo/client';
import { NamedGQLResult, wrapRestQuery } from '@encoura/apollo-rest-utils';

import { REST } from '~/constants/rest';
import USER_TYPES from '~/constants/userTypes';
import { FiniteQueryState, toFiniteState } from '~/helpers/apollo/finiteQueryState';
import { EducatorInvite } from '~/types/educator';

import useActiveUser from '../useActiveUser';

type QueryType = NamedGQLResult<'getEducatorData', EducatorInvite>;

// This should be moved to '/src/graphql' eventually. For now, this is still going through wrap-rest-utils
// because we need to change the backend to accept calls to this entity without the x-api-key.
export const GET_EDUCATOR_DATA = gql`
  query EducatorDataQuery($uid: String!, $hsId: String!) {
    getEducatorData(uid: $uid, hsId: $hsId) {
      contact_id
      invite_codes
    }
  }
`;

export const useEducatorInviteCode = (schoolId: string): FiniteQueryState<QueryType> => {
  const activeUser = useActiveUser();
  const query = wrapRestQuery<'getEducatorData'>()(GET_EDUCATOR_DATA, {
    endpoint: REST.GET.EDUCATOR_BY_UID_BY_HSID,
    skip: !activeUser || activeUser?.type === USER_TYPES.LDAP,
    variables: {
      hsId: schoolId,
      uid: activeUser?.uid ?? '',
    },
  });

  return toFiniteState(query);
};

export default useEducatorInviteCode;
