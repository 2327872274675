import NEXT from '../next';

const isStorybook = process.env.STORYBOOK;

const ENV: NodeJS.ProcessEnv =
  NEXT.IS_SERVER || isStorybook
    ? process.env
    : ((window as unknown as Record<string, unknown>).__ENV__ as NodeJS.ProcessEnv);

export default ENV;
