import MUIMenuItem from '@mui/material/MenuItem';
import { noop } from 'lodash';
import { FC, MouseEventHandler, ReactElement } from 'react';

import userHasAccessToModuleOnSecondarySchool from '~/helpers/userHasAccessToModuleOnSecondarySchool';
import useActiveUser from '~/hooks/useActiveUser';
import useDetailedPermissions from '~/hooks/useDetailedPermissions';
import { DetailedPermissions, IPermissioned } from '~/types';

import { MenuItemLink } from './styles';

export interface IMenuItem extends IPermissioned {
  externalLink?: boolean;
  href?: string;
  id: string;
  onClick?: MouseEventHandler;
  title: string;
  visible?: boolean;
}

const MenuItem: FC<IMenuItem> = ({
  externalLink = false,
  href,
  id,
  onClick = noop,
  module,
  schoolId,
  title,
  visible = true,
}: IMenuItem): ReactElement | null => {
  const me = useActiveUser();
  const { data: detailedPermissionsData, loading: detailedPermissionsLoading } = useDetailedPermissions();

  const detailedPermissions: DetailedPermissions | undefined = detailedPermissionsData ? detailedPermissionsData.detailedPermissions : undefined;

  if (
    detailedPermissionsLoading ||
    (module &&
      !userHasAccessToModuleOnSecondarySchool({
        detailedPermissions,
        me,
        module,
        schoolId,
      })) ||
    !visible
  ) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  /**
   * Removing padding from MUIMenuItem and adding it on MenuItemLink because moving the onclick option to the mui component
   * was injecting a href error (schoolId was empty after impersonating). The padding was added to the MenuItemLink
   * to avoid clicking outside the MenuItemLink not triggering the desired event
   */
  return (
    <MUIMenuItem id={`${id}-menu-item`} sx={{ padding: 0 }}>
      <MenuItemLink externalLink={externalLink} href={href} id={id} key={id} onClick={onClick} sx={{ padding: '8px 16px' }} underline="none">
        {title}
      </MenuItemLink>
    </MUIMenuItem>
  );
};

export default MenuItem;
