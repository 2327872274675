import first from 'lodash/first';

import { EDUCATION_PARTNER_HS_ID, HIGH_SCHOOL_REPORTS, HIGH_SCHOOL_REPORTS_CODES } from '~/constants/reports';
import { HS_REGEX, ROUTES, ROUTES_REGEX } from '~/constants/routes';
import USER_TYPES from '~/constants/userTypes';
import { getDefaultDataSources, getDefaultHighSchoolYears } from '~/helpers/students';
import { WalkMeSetUserData } from '~/helpers/walkme/WalkMeInsights';

import { Role } from '~/constants/roles';
import useActiveUser from '../useActiveUser';
import useDetailedPermissions from '../useDetailedPermissions';
import useEducatorInviteCode from '../useEducatorInviteCode';
import useMyStudentQuery from '../useMyStudentQuery';
import useQueryParam from '../useQueryParam';

const useLandingPageRedirect = (hsId: string): { url: string; loading: boolean } => {
  const activeUser = useActiveUser();
  const getDetailedPermissions = useDetailedPermissions();
  const returnUrl = useQueryParam('returnUrl');

  WalkMeSetUserData({ role: undefined, uid: activeUser?.uid as string });

  const { data: educatorData, loading: loadingEducatorData } = useEducatorInviteCode(hsId);
  const educatorInviteCodes = educatorData?.getEducatorData?.invite_codes;
  const inviteCode =
    educatorInviteCodes?.find(inviteCodeObject => inviteCodeObject.school_id === hsId)?.invite_code ?? '';

  const commonQueryParams = {
    hsId,
    inviteCode,
    uid: activeUser?.uid as string,
  };

  const { data: unfilteredStudentsData, loading: loadingUnfilteredStudents } = useMyStudentQuery({
    dataSources: getDefaultDataSources().join(','),
    fetchJustStudentIds: true,
    gradYears: getDefaultHighSchoolYears().join(','),
    limit: 1,
    loading: loadingEducatorData,
    offset: 0,
    search: '',
    sort: '',
    surveyEndDate: '',
    surveyStartDate: '',
    ...commonQueryParams,
  });

  const hasAnyStudent = (unfilteredStudentsData?.getEducatorStudents?.length || 0) > 0;

  const isStudentDataLoading = loadingEducatorData || loadingUnfilteredStudents;

  const loading = getDetailedPermissions.loading || !activeUser || isStudentDataLoading;

  if (returnUrl && !loading) {
    const returnUrlWithShoolIdCompare = `${returnUrl as string}/${HS_REGEX}`.replace(/\//g, '\\/');
    let routeWithShoolIdRegex = false;

    Object.entries(ROUTES_REGEX).forEach(route => {
      if (route[1] === returnUrlWithShoolIdCompare) routeWithShoolIdRegex = true;
    });

    if (hsId === EDUCATION_PARTNER_HS_ID && activeUser?.type !== USER_TYPES.LDAP) {
      WalkMeSetUserData({ role: 'partner', uid: activeUser?.uid });
    }

    return {
      loading: false,
      url: routeWithShoolIdRegex ? `${returnUrl as string}/${hsId}` : (returnUrl as string),
    };
  }

  const detailedPermissions = getDetailedPermissions.data?.detailedPermissions;
  const userHsIds = detailedPermissions?.map(permission => permission.organization?.externalId);

  const hasSuperintendentRole = detailedPermissions?.some(item => item.organization?.role === Role.SUPERINTENDENT);

  if (hsId === EDUCATION_PARTNER_HS_ID && detailedPermissions && detailedPermissions.length > 0) {
    const partnerOrgUserPermissions = detailedPermissions.filter(
      schoolPermission => schoolPermission.organization?.externalId === EDUCATION_PARTNER_HS_ID,
    );
    const userPermissions = partnerOrgUserPermissions.map(({ permissions }) => permissions);
    const partnerSpecificReport: string[] = [];

    userPermissions.forEach(
      permissions =>
        permissions &&
        Object.entries(permissions).forEach(([key, value]) => {
          if (value && HIGH_SCHOOL_REPORTS_CODES.includes(key)) {
            partnerSpecificReport.push(key);
          }
        }),
    );

    WalkMeSetUserData({ role: 'partner', uid: activeUser?.uid as string });

    let firstReportPage = ROUTES.REPORTS_NATIONAL_REPORT;
    if (partnerSpecificReport.length > 0) {
      const specificReport = first(partnerSpecificReport.sort());
      if (specificReport) {
        firstReportPage = `${HIGH_SCHOOL_REPORTS[specificReport].route}/${hsId}`;
      }
    }

    return {
      loading: false,
      url: firstReportPage,
    };
  }

  if (activeUser?.type === USER_TYPES.LDAP) {
    WalkMeSetUserData({ role: 'ldap_user', uid: activeUser?.uid });
    return {
      loading: false,
      url: `${ROUTES.ADMINISTRATION}/${hsId}`,
    };
  }

  if (userHsIds && userHsIds.length < 1) {
    return {
      loading,
      url: hasAnyStudent || hasSuperintendentRole ? `${ROUTES.MY_STUDENTS}/${hsId}` : `${ROUTES.LESSON_PLANS}/${hsId}`, // To be changed in Epic E4E-1260 for new Superintendent UX
    };
  }

  if (userHsIds && userHsIds.length >= 1) {
    const hsIdIndex = userHsIds.indexOf(hsId, 0);
    const permissions = detailedPermissions && detailedPermissions[hsIdIndex].permissions;
    if (permissions?.highSchoolAdmin) {
      return {
        loading,
        url: `${ROUTES.ADMINISTRATION}/${hsId}`,
      };
    }
  }

  return {
    loading,
    url: hasAnyStudent || hasSuperintendentRole ? `${ROUTES.MY_STUDENTS}/${hsId}` : `${ROUTES.LESSON_PLANS}/${hsId}`, // To be changed in Epic E4E-1260 for new Superintendent UX
  };
};

export default useLandingPageRedirect;
