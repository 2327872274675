import { gql } from '@apollo/client';
import { wrapRestQuery } from '@encoura/apollo-rest-utils';
import { jwtDecode } from 'jwt-decode';

import NAMES from '~/constants/names';
import REST from '~/constants/rest';
import USER_TYPES from '~/constants/userTypes';
import localCache from '~/helpers/localCache';
import sentryGraphqlRequestError from '~/helpers/SentryErrors/sentryGraphqlRequestError';
import { IActiveUser, IImpersonating } from '~/types';

export const USER_PROFILE_QUERY = gql`
  query UserProfileQuery($uid: String!) {
    userProfile(uid: $uid) {
      cell_phone
      email
      fax_phone
      firstName
      lastName
      type
      uid
      userName
    }
  }
`;

const useActiveUser = (): IActiveUser | null => {
  try {
    const token = localCache.get(NAMES.TOKEN);
    const impersonatingUser = localCache.get<IImpersonating>(NAMES.IMPERSONATING);

    const tokenDecoded = token ? jwtDecode<IActiveUser>(token) : undefined;
    const impersonatingTokenDecoded = impersonatingUser
      ? jwtDecode<IActiveUser>(impersonatingUser.sessionToken)
      : undefined;

    const { data } = wrapRestQuery<'userProfile'>()(USER_PROFILE_QUERY, {
      endpoint: REST.GET.USERS_BY_UID,
      skip: !tokenDecoded || (tokenDecoded.type === USER_TYPES.LDAP && !impersonatingUser),
      variables: {
        uid: impersonatingTokenDecoded ? impersonatingTokenDecoded.uid : (tokenDecoded?.uid as string),
      },
    });

    if (!tokenDecoded) {
      return null;
    }

    const expiresAt = tokenDecoded.exp * 1000;
    const now = new Date().getTime();
    if (expiresAt <= now) {
      return null;
    }

    // LDAP users are not fetched dynamically because
    // `/users/:userId` endpoint is returning a 500 error for them.
    if (tokenDecoded.type === USER_TYPES.LDAP && !impersonatingTokenDecoded) {
      return tokenDecoded;
    }

    if (impersonatingTokenDecoded) {
      impersonatingTokenDecoded.rootUser = tokenDecoded;
      return { ...impersonatingTokenDecoded, ...data?.userProfile };
    }

    return { ...tokenDecoded, ...data?.userProfile };
  } catch (unkErr: unknown) {
    sentryGraphqlRequestError({ error: unkErr, level: 'error', variables: {} });
    return null;
  }
};

export default useActiveUser;
