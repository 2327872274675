export const EducatorSearchText = {
  inputFocusedPlaceholder: 'School or Role',
  inputPlaceholder: 'Select school or role',
  title: 'Select School or Role',
};

export const LDAPSearchText = {
  inputFocusedPlaceholder: 'School Name or Location',
  inputPlaceholder: 'Search school by name or location',
  title: 'Select a School or Location',
};

export interface ITextStrings {
  title: string;
  inputFocusedPlaceholder: string;
  inputPlaceholder: string;
}
