import Grid from '@mui/material/Grid';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Typography, { TypographyTypeMap } from '@mui/material/Typography';

import Icon from '~/components/Icon';
import { styled } from '~/helpers/styled';

export const ReportCard = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.branding.surface[50],
  border: `1px solid ${theme.palette.branding.surface[200]}`,
  borderRadius: '8px',
  cursor: 'pointer',
  padding: '16px',
}));

export const IconContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.branding.mist[300],
  borderRadius: '96px',
  height: '64px',
  width: '64px',
}));

export const IconBackground = styled(Grid)(() => ({
  height: '24px',
  width: '24px',
}));

export const ReportNameGrid = styled(Grid)(() => ({
  padding: '24px 0px',
}));

export const IconStyled = styled(Icon)(({ theme }) => ({
  color: theme.palette.branding.navy[500],
}));

/**
 * This is a know issue with material and TS. When using styled components that uses the component prop we need
 * to cast back the element so it will add the prop again. For this specific case we have to use the OverridableComponent
 * because we are using a custom prop (textLines) together
 */
export const ReportNameTypography = styled(Typography)<{ $textLines: number }>(context => ({
  color: context.theme.palette.branding.navy[500],
  display: 'flex',
  minHeight: context.$textLines * 24,
})) as OverridableComponent<TypographyTypeMap<{ $textLines: number }>>;
