import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { styled } from '~/helpers/styled';

export const RootPaperContainer = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
}));

export const RootFootContainer = styled(Grid)(() => ({
  height: '52px',
  marginTop: 'auto',
}));
