import { isApolloError } from '@apollo/client';
import { isError, isObject, isString } from 'lodash';

import { SeverityType } from '~/components/Alert';

import GenericError from './genericError';
import InvalidQueryError from './invalidQueryError';
import UnknownError from './unknownError';

/**
 * Returns an appropriate instance of Error for any unknown caught error.
 * Should be called in every "catch" clause to convert the unknown caught value type.
 */
export function asError(unkErr: unknown): Error {
  try {
    if (isError(unkErr)) {
      return unkErr;
    }
    if (isObject(unkErr)) {
      return new GenericError(unkErr as Record<string, unknown>);
    }
    return new UnknownError(unkErr);
  } catch (_: unknown) {
    // Should never happen, but just in case everything fails, we still need
    // to continue the error handling from the caller.
    /* istanbul ignore next */
    return unkErr as Error;
  }
}

/**
 * Returns an user-friendly error message, for the purpose of being displayed in the UI.
 */
export function getFriendlyErrorMessage(err: Error): string {
  if (isApolloError(err) && err.networkError) {
    const serverMessage =
      // eslint-disable-next-line no-nested-ternary
      'result' in err.networkError
        ? isString(err.networkError.result)
          ? err.networkError.result
          : (err.networkError.result.message as string)
        : undefined;
    return serverMessage ?? err.networkError.message;
  }
  if (err instanceof GenericError) {
    const { message, status } = err.errorData;
    if (message) return `Oops, a problem ocurred: ${message ?? ''}`;
    if (status) return `Oops, a problem ocurred! Error status: ${status ? String(status) : ''}`;
    return `Oops, a problem ocurred!`;
  }
  return err.message;
}

/**
 * Converts an Error object to a snackbar alert.
 * For usage with "AlertContext.addAlert" action function from @actinc/dls.
 */
export function toErrorAlert(err: Error): { message: string; options: { variant: SeverityType } } {
  return {
    message: getFriendlyErrorMessage(err),
    options: {
      variant: 'error',
    },
  };
}

export { GenericError, InvalidQueryError, UnknownError };
