import { gql } from '@apollo/client';
import { wrapRestQuery } from '@encoura/apollo-rest-utils';
import { FC, useState } from 'react';

import { REST } from '~/constants/rest';
import { LDAPSearchText } from '~/constants/schoolSearch';

import SchoolSearch from '../SchoolSearch';

export const SCHOOL_SEARCH_QUERY = gql`
  query SchoolSearchQuery($search: String!) {
    schools(search: $search) {
      city
      hs_id
      name
      state
    }
  }
`;

interface IProps {
  closeDialog?: () => void;
}

const LdapSchoolSearch: FC<IProps> = props => {
  const { closeDialog } = props;

  const [searchQuery, setSearchQuery] = useState('');

  const wrappedRestQuery = wrapRestQuery<'schools'>();

  const { data, loading } = wrappedRestQuery(SCHOOL_SEARCH_QUERY, {
    endpoint: REST.GET.HIGH_SCHOOLS_SEARCH,
    skip: !searchQuery,
    variables: {
      search: searchQuery,
    },
  });

  const dataState = loading ? 'loading' : 'loaded';
  const schools = data?.schools ?? [];

  return (
    <SchoolSearch
      closeDialog={closeDialog}
      items={schools}
      onChangeSearchText={setSearchQuery}
      searchText={searchQuery}
      state={dataState}
      texts={LDAPSearchText}
      userIsLdap
    />
  );
};

export default LdapSchoolSearch;
