import * as React from 'react';

export interface IHomeDialogSignalContext {
  clearRequest: () => void;
  requested: boolean;
  requestHomeDialog: () => void;
}

export const HomeDialogSignalContext = React.createContext<IHomeDialogSignalContext>({
  clearRequest: () => {
    throw new Error('Unimplemented');
  },
  requested: false,
  requestHomeDialog: () => {
    throw new Error('Unimplemented');
  },
});

export const HomeDialogSignalProvider = HomeDialogSignalContext.Provider;

export interface IHomeDialogSignalProviderComponentProps {
  children: React.ReactNode;
}

export const HomeDialogSignalProviderComponent: React.FC<IHomeDialogSignalProviderComponentProps> = ({ children }) => {
  const [requested, setRequested] = React.useState(false);
  const requestHomeDialog = (): void => setRequested(true);
  const clearRequest = (): void => setRequested(false);

  return <HomeDialogSignalProvider value={{ clearRequest, requested, requestHomeDialog }}>{children}</HomeDialogSignalProvider>;
};
