import ReactGA from 'react-ga';

import ENV from '~/constants/env';

export const analyticsTrack = (pathname: string): void => {
  if (ENV.GOOGLE_ANALYTICS_ID) {
    ReactGA.set({ page: pathname }); // Set user current page
    ReactGA.pageview(pathname); // Track a new page view
  }
};

export const analyticsSetUp = (): void => {
  if (ENV.GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(ENV.GOOGLE_ANALYTICS_ID);
  }
};
