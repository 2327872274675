import { useRouter } from 'next/router';

import extractQueryParams from '~/helpers/extractQueryParams';

// So why is this hook necessary?
//
// Because when rendering the site locally (through node), NextJS does a multi-pass population
// of the router object (including the `query` property), and then sets `isReady` to true when
// it's done. However, when running the site statically (such as through S3), it does it in one
// pass and does NOT set `isReady` to true. So this makes it REALLY tough to know when a query
// param is missing because NextJS hasn't populated it yet vs. when it's actually not present.
// So the following code attempts to pull query params from the `query` property if it can, but
// will manually parse `asPath` if it needs to in order to extract query params itself.

export const useQueryParam = (param: string): string | string[] | undefined => {
  const router = useRouter();
  if (param in router.query) {
    return router.query[param];
  }
  if (!router.asPath.includes(param)) {
    return undefined;
  }

  const params = extractQueryParams(router.asPath);
  if (!params) {
    return undefined;
  }
  if (param in params) {
    return params[param];
  }
  return undefined;
};

export default useQueryParam;
