import React from 'react';

import HomeDialog, { HOME_MODAL_VERSION } from '~/components/HomeDialog';
import { HOME_MODAL } from '~/constants/featureFlags';
import TAG_IDS from '~/constants/tagIds';
import { UMS_ATTRIBUTES } from '~/constants/umsAttributes';
import useActiveUserAttributes from '~/hooks/useActiveUserAttributes';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { HomeDialogSignalContext } from '~/providers/HomeDialogSignal';

const HomeDialogHandler: React.FC = () => {
  const featureFlags = useFeatureFlags();
  const { data: featureEnabled } = featureFlags(HOME_MODAL);
  const HomeDialogSignal = React.useContext(HomeDialogSignalContext);
  const userAttributes = useActiveUserAttributes();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [openCheck, setOpenCheck] = React.useState(false);

  React.useEffect(() => {
    setOpenCheck(false);
  }, [HomeDialogSignal.requested]);

  React.useEffect(() => {
    if (openCheck) return;
    if (!HomeDialogSignal.requested) return;
    if (!userAttributes) return;
    if (!featureEnabled) return;

    const shouldOpen = (userAttributes[UMS_ATTRIBUTES.USER.HOME_MODAL] ?? 0) < HOME_MODAL_VERSION;
    setDialogOpen(shouldOpen);
    setOpenCheck(true);
    HomeDialogSignal.clearRequest();
  }, [featureEnabled, HomeDialogSignal, userAttributes, openCheck]);

  const handleClose = (): void => {
    setDialogOpen(false);
  };

  return <HomeDialog closeDialog={handleClose} id={TAG_IDS.DIALOGS.HOME.ROOT_DEFAULT} open={dialogOpen} />;
};

export default HomeDialogHandler;
