import { useRouter } from 'next/router';
import * as React from 'react';

import { getDefaultDataSources, getDefaultHighSchoolYears } from '~/helpers/students';

const SHARED_STATE_PATHS = [
  // Paths in which the provider state is kept. State will be discarded when navigating away.
  '/MyStudents',
  '/MyStudents/[schoolId]',
  '/MyStudents/[schoolId]/[studentId]',
];

export interface IMyStudentsSelectionContext {
  clearSelection: () => void;
  isCMRDialogOpened: boolean;
  loadSelection: (selection: IStudentSelectionData[]) => void;
  selectedStudents: IStudentSelectionData[];
  selectedFilters: IFilterObject;
  setIsCMRDialogOpened: (value: boolean) => void;
  setFilters: (...[filter, data]: FilterTypeTuples) => void;
  toggleStudent: (data: IStudentSelectionData) => void;
}

export enum Filter {
  GRAD_YEAR_FILTER = 'GRAD_YEAR_FILTER',
  SEARCH_FILTER = 'SEARCH_FILTER',
  SURVEY_DATE_FILTER = 'SURVEY_DATE_FILTER',
  DATA_SOURCE_FILTER = 'DATA_SOURCE_FILTER',
  ALL_FILTERS = 'ALL_FILTERS',
}

export type FilterTypeTuples =
  | [Filter.GRAD_YEAR_FILTER, (number | 'UNKNOWN')[]]
  | [Filter.SURVEY_DATE_FILTER, ISurveyDate]
  | [Filter.DATA_SOURCE_FILTER, string[]]
  | [Filter.SEARCH_FILTER, string]
  | [Filter.ALL_FILTERS, IFilterObject];

export interface ISurveyDate {
  surveyEndDate: Date | null;
  surveyStartDate: Date | null;
}

export interface IStudentSelectionData {
  student_key: string;
  gradYear: number | 'UNKNOWN';
  mostRecentDataSource?: string;
  surveyDate: string | null;
}

export interface IFilterObject {
  dataSources: string[];
  gradYears: (number | 'UNKNOWN')[];
  surveyDate: ISurveyDate;
  search: string;
}

export const MyStudentsSelectionContext = React.createContext<IMyStudentsSelectionContext>({
  clearSelection: () => {
    throw new Error('Unimplemented');
  },
  isCMRDialogOpened: false,
  loadSelection: () => {
    throw new Error('Unimplemented');
  },
  selectedFilters: {
    dataSources: getDefaultDataSources(),
    gradYears: getDefaultHighSchoolYears(),
    search: '',
    surveyDate: {
      surveyEndDate: null,
      surveyStartDate: null,
    },
  },
  selectedStudents: [],
  setFilters: () => {
    throw new Error('Unimplemented');
  },
  setIsCMRDialogOpened: () => {
    throw new Error('Unimplemented');
  },
  toggleStudent: () => {
    throw new Error('Unimplemented');
  },
});

export const MyStudentsSelectionProvider = MyStudentsSelectionContext.Provider;

const emptyFilterObject = {
  dataSources: new Array<string>(),
  gradYears: new Array<number>(),
  search: '',
  surveyDate: {
    surveyEndDate: null,
    surveyStartDate: null,
  },
};

export const MyStudentsSelectionProviderComponent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedStudents, setSelectedStudents] = React.useState<IStudentSelectionData[]>([]);

  const [selectedFilters, setSelectedFilters] = React.useState<IFilterObject>(emptyFilterObject);
  const [isCMRDialogOpened, setIsCMRDialogOpened] = React.useState<boolean>(false);
  const router = useRouter();

  const loadSelection = (newSelection: IStudentSelectionData[]): void => {
    setSelectedStudents(newSelection);
  };
  const clearSelection = (): void => {
    loadSelection([]);
  };

  const toggleStudent = React.useCallback(
    (studentData: IStudentSelectionData) => {
      const newSelection = [...selectedStudents];
      const position = newSelection.findIndex((student: IStudentSelectionData) => student.student_key === studentData.student_key);
      if (position >= 0) {
        newSelection.splice(position, 1);
      } else {
        newSelection.push(studentData);
      }
      setSelectedStudents(newSelection);
    },
    [selectedStudents],
  );

  const refetchStudentSelection = React.useCallback(() => {
    let newSelection = [...selectedStudents];
    if (selectedFilters.gradYears) {
      newSelection = selectedStudents.filter((student: IStudentSelectionData) => selectedFilters.gradYears.includes(student.gradYear));
    }
    const { surveyEndDate, surveyStartDate } = selectedFilters.surveyDate;
    if (surveyEndDate && surveyStartDate) {
      const endDate = surveyEndDate.toISOString();
      const startDate = surveyStartDate.toISOString();
      newSelection = newSelection.filter(
        (student: IStudentSelectionData) => student.surveyDate && endDate >= student.surveyDate && startDate <= student.surveyDate,
      );
    }
    setSelectedStudents(newSelection);
  }, [selectedFilters.gradYears, selectedFilters.surveyDate, selectedStudents]);

  const setFilters = React.useCallback(
    (...[filter, data]: FilterTypeTuples): void => {
      if (filter === Filter.ALL_FILTERS) {
        setSelectedFilters(data);
        refetchStudentSelection();
      } else if (filter === Filter.GRAD_YEAR_FILTER) {
        setSelectedFilters({ ...selectedFilters, gradYears: data });
        refetchStudentSelection();
      } else if (filter === Filter.SURVEY_DATE_FILTER) {
        setSelectedFilters({ ...selectedFilters, surveyDate: data });
        refetchStudentSelection();
      } else if (filter === Filter.DATA_SOURCE_FILTER) {
        setSelectedFilters({ ...selectedFilters, dataSources: data });
        refetchStudentSelection();
      } else if (filter === Filter.SEARCH_FILTER) {
        setSelectedFilters({ ...selectedFilters, search: data });
      }
    },
    [refetchStudentSelection, selectedFilters],
  );

  React.useEffect(() => {
    const handler = (): void => {
      if (!SHARED_STATE_PATHS.includes(router.pathname)) {
        clearSelection();
        setSelectedFilters(emptyFilterObject);
      }
    };
    router.events.on('routeChangeComplete', handler);

    return (): void => {
      router.events.off('routeChangeComplete', handler);
    };
  });

  return (
    <MyStudentsSelectionProvider
      value={{
        clearSelection,
        isCMRDialogOpened,
        loadSelection,
        selectedFilters,
        selectedStudents,
        setFilters,
        setIsCMRDialogOpened,
        toggleStudent,
      }}
    >
      {children}
    </MyStudentsSelectionProvider>
  );
};
