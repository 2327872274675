// eslint-disable-next-line no-restricted-imports
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import { createThemeStyled } from '~/helpers/styled';
import THEME_ENCOURAGE_E4E from '~/styles/ThemeEncourageE4E';

interface IIconButtonProps extends IconButtonProps {
  disableHover?: boolean;
  padding?: 'small' | 'medium';
}

const styled = createThemeStyled<typeof THEME_ENCOURAGE_E4E>();

// eslint-disable-next-line import/prefer-default-export
export const CustomIconButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'disableHover' && prop !== 'padding',
})<IIconButtonProps>(({ disableHover, padding, size }) => {
  return {
    ...(disableHover && {
      '&.MuiButtonBase-root': {
        '&:hover': {
          background: 'none',
        },
      },
    }),
    ...(padding === 'small' &&
      size === 'small' && {
        height: 24,
        padding: 4,
        width: 24,
      }),
    ...(padding === 'small' &&
      size === 'medium' && {
        height: 32,
        padding: 4,
        width: 32,
      }),
    ...(padding === 'small' &&
      size === 'large' && {
        height: 40,
        padding: 12,
        width: 40,
      }),
    ...(padding === 'medium' &&
      size === 'small' && {
        height: 40,
        padding: 12,
        width: 40,
      }),
    ...(padding === 'medium' &&
      size === 'medium' && {
        height: 48,
        padding: 0,
        width: 48,
      }),
    ...(padding === 'medium' &&
      size === 'large' && {
        height: 56,
        padding: 0,
        width: 56,
      }),
  };
});
