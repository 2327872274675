import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { createThemeStyled } from '~/helpers/styled';
import Leaves from '~/public/static/img/leaves.png';
import THEME_ENCOURAGE_E4E from '~/styles/ThemeEncourageE4E';

const styled = createThemeStyled<typeof THEME_ENCOURAGE_E4E>();

export const ContentLayoutGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  all: 'unset',
  backgroundColor: theme.palette.branding.sapphire[50],
  backgroundImage: `url(${Leaves.src})`,
  backgroundPositionY: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 27.4%',
  display: 'flex',
  flex: '1 1 100%',
  flexDirection: 'column',
  height: '93vh',
  marginLeft: theme.spacing(-3),
  position: 'relative',
  width: '100vw',
}));

export const ContentHeader = styled(Typography)(() => ({
  marginTop: '40px',
}));

export const ContentMessage = styled(Typography)(() => ({
  fontSize: '20px',
  marginTop: '30px',
  textAlign: 'center',
}));
