/* eslint-disable @typescript-eslint/no-shadow */
import { ROUTES } from './routes';

// Report modules
export const ART = 'reportART';
export const ACTE = 'reportACTE';
export const ACTFL = 'reportACTFL';
export const COF = 'reportCOF';
export const DECA = 'reportDECA';
export const ENCOURAGE_USERS = '_reportEncourageUsers';
export const FBLA = 'reportFBLA';
export const FCCLA = 'reportFCCLA';
export const MAA = 'reportMAA';
export const MCO = 'reportMCO';
export const MCS = 'reportMCS';
export const NABSE = 'reportNABSE';
export const NAFME = 'reportNAFME';
export const NCSS = 'reportNCSS';
export const REPORTS_NATIONAL = 'reportNational';
export const SHAPE = 'reportSHAPE';
export const STEM = 'reportSTEM';
export const SUPERINTENDENT = 'reportSUPERINTENDENT';

export const TEST_HS_ID = 'HS031635';
export const EDUCATION_PARTNER_HS_ID = 'HS074JM5';
export const PARTNER_REPORT_VERSION = 'Partner';
export const EDUCATOR_REPORT_VERSION = 'HSTR';

export const HIGH_SCHOOL_REPORTS_CODES = [
  ACTE,
  ACTFL,
  COF,
  DECA,
  FBLA,
  FCCLA,
  MAA,
  MCO,
  MCS,
  NABSE,
  NAFME,
  NCSS,
  SHAPE,
  STEM,
];

export const HIGH_SCHOOL_REPORTS: Record<string, Record<string, string>> = {
  reportACTE: {
    default: 'ACTE Report',
    educator: 'ACTE Educator Report',
    id: 'report-acte',
    route: '/Reports/ACTEHighSchoolTeacherReport',
  },
  reportACTFL: {
    default: 'ACTFL Report',
    educator: 'ACTFL Educator Report',
    id: 'report-actfl',
    route: '/Reports/ACTFLHighSchoolTeacherReport',
  },
  reportART: {
    default: 'ART Report',
    educator: 'ART Educator Report',
    id: 'report-art',
    route: '/Reports/ARTHighSchoolTeacherReport',
  },
  reportCOF: {
    default: 'COF/JROTC Report',
    educator: 'COF/JROTC Educator Report',
    id: 'report-cof',
    route: '/Reports/COFJROTCHighSchoolTeacherReport',
  },
  reportDECA: {
    default: 'DECA Report',
    educator: 'DECA Educator Report',
    id: 'report-deca',
    route: '/Reports/DECAHighSchoolTeacherReport',
  },
  reportFBLA: {
    default: 'FBLA Report',
    educator: 'FBLA Educator Report',
    id: 'report-fbla',
    route: '/Reports/FBLAHighSchoolTeacherReport',
  },
  reportFCCLA: {
    default: 'FCCLA Report',
    educator: 'FCCLA Educator Report',
    id: 'report-fccla',
    route: '/Reports/FCCLAHighSchoolTeacherReport',
  },
  reportMAA: {
    default: 'MAA Report',
    educator: 'MAA Educator Report',
    id: 'report-maa',
    route: '/Reports/MAAHighSchoolTeacherReport',
  },
  reportMCO: {
    alternativeTitle: 'School Report', // Title for counselors and principals
    default: 'myOptions Encourage Report',
    educator: 'myOptions Encourage Educator Report',
    id: 'report-mco',
    route: '/Reports/MOHighSchoolTeacherReport',
  },
  reportMCS: {
    alternativeTitle: 'School Report', // Title for counselors and principals
    default: 'myOptions Encourage Report',
    educator: 'myOptions Encourage Educator Report',
    id: 'report-mcs',
    route: '/Reports/MOHighSchoolTeacherReport', // Same route as MCO, since they are the same report
  },
  reportNABSE: {
    default: 'NABSE Report',
    educator: 'NABSE Educator Report',
    id: 'report-nabse',
    route: '/Reports/NABSEHighSchoolTeacherReport',
  },
  reportNAFME: {
    default: 'NAFME Report',
    educator: 'NAFME Educator Report',
    id: 'report-nafme',
    route: '/Reports/NAFMEHighSchoolTeacherReport',
  },
  reportNATIONAL: {
    default: 'National Report',
    id: 'report-national',
    route: '/Reports/NationalReport',
  },
  reportNCSS: {
    default: 'NCSS Report',
    educator: 'NCSS Educator Report',
    id: 'report-ncss',
    route: '/Reports/NCSSHighSchoolTeacherReport',
  },
  reportSHAPE: {
    default: 'SHAPE America Report',
    educator: 'SHAPE America Educator Report',
    id: 'report-shape',
    route: '/Reports/SHAPEHighSchoolTeacherReport',
  },
  reportSTEM: {
    default: 'STEMconnector Report',
    educator: 'STEMconnector Educator Report',
    id: 'report-stem',
    route: '/Reports/STEMHighSchoolTeacherReport',
  },
  reportSUPERINTENDENT: {
    default: 'Superintendent Report',
    id: 'report-superintendent',
    route: '/Reports/SuperintendentReport',
  },
};

export const PARTNER_REPORTS_TYPES = [
  {
    label: 'ACTE',
    value: 'ACTE',
  },
  {
    label: 'ACTFL',
    value: 'ACTFL',
  },
  {
    label: 'COF/JROTC',
    value: 'COF',
  },
  {
    label: 'DECA',
    value: 'DECA',
  },
  {
    label: 'FBLA',
    value: 'FBLA',
  },
  {
    label: 'FCCLA',
    value: 'FCCLA',
  },
  {
    label: 'MAA',
    value: 'MAA',
  },
  {
    label: 'NABSE',
    value: 'NABSE',
  },
  {
    label: 'NAFME',
    value: 'NAFME',
  },
  {
    label: 'NCSS',
    value: 'NCSS',
  },
  {
    label: 'SHAPE America',
    value: 'SHAPE',
  },
  {
    label: 'STEMconnector',
    value: 'STEM',
  },
];

export const HighSchoolReportsArray = [
  'ACTE',
  'ART',
  'ACTFL',
  'COFJROTC',
  'DECA',
  'FBLA',
  'FCCLA',
  'MAA',
  'MCO',
  'NABSE',
  'NAFME',
  'NCSS',
  'SHAPE',
  'STEM',
] as const;

export const EducatorReportsArray = [
  'NationalReport',
  'SuperintendentReport',
  'SuperintendentDistrictPSReport',
] as const;

export enum ReportsKey {
  ACTE = 'ACTE',
  ART = 'ART',
  ACTFL = 'ACTFL',
  COFJROTC = 'COFJROTC',
  DECA = 'DECA',
  FBLA = 'FBLA',
  FCCLA = 'FCCLA',
  MAA = 'MAA',
  MCO = 'MCO',
  NABSE = 'NABSE',
  NAFME = 'NAFME',
  NationalReport = 'NationalReport',
  NCSS = 'NCSS',
  SHAPE = 'SHAPE',
  STEM = 'STEM',
  SuperintendentReport = 'SuperintendentReport',
  SuperintendentDistrictPSReport = 'SuperintendentDistrictPSReport',
}

export const ReportRouter: Record<ReportsKey, string> = {
  [ReportsKey.ACTE]: ROUTES.REPORTS_ACTE_REPORT,
  [ReportsKey.ACTFL]: ROUTES.REPORTS_ACTFL_REPORT,
  [ReportsKey.ART]: ROUTES.REPORTS_ART_REPORT,
  [ReportsKey.COFJROTC]: ROUTES.REPORTS_COF_JROTC_REPORT,
  [ReportsKey.DECA]: ROUTES.REPORTS_DECA_REPORT,
  [ReportsKey.FBLA]: ROUTES.REPORTS_FBLA_REPORT,
  [ReportsKey.FCCLA]: ROUTES.REPORTS_FCCLA_REPORT,
  [ReportsKey.MAA]: ROUTES.REPORTS_MAA_REPORT,
  [ReportsKey.MCO]: ROUTES.REPORTS_MO_REPORT,
  [ReportsKey.NABSE]: ROUTES.REPORTS_NABSE_REPORT,
  [ReportsKey.NAFME]: ROUTES.REPORTS_NAFME_REPORT,
  [ReportsKey.NationalReport]: ROUTES.REPORTS_NATIONAL_REPORT,
  [ReportsKey.NCSS]: ROUTES.REPORTS_NCSS_REPORT,
  [ReportsKey.SHAPE]: ROUTES.REPORTS_SHAPE_AMERICA_REPORT,
  [ReportsKey.STEM]: ROUTES.REPORTS_STEM_CONNECTOR_REPORT,
  [ReportsKey.SuperintendentDistrictPSReport]: ROUTES.REPORTS_SUPERINTENDENT_DISTRICT_POSTSECONDARY_REPORT,
  [ReportsKey.SuperintendentReport]: ROUTES.REPORTS_SUPERINTENDENT_REPORT,
};
