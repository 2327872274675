import React from 'react';

function asStringOrNull(val: unknown): string | null {
  return val !== null ? String(val) : null;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function useLocalStorage(key: string, defaultValue: unknown) {
  // State is used as a mechanism to trigger component re-rendering
  const [value, setValue] = React.useState<string | null>(global?.window?.localStorage.getItem(key) || asStringOrNull(defaultValue));

  // Setter which handles any value, will update the component and
  // update the value in local storage.
  const setLocalStorage = (newValue: unknown): void => {
    const storingValue = asStringOrNull(newValue);
    if (storingValue) {
      global?.window?.localStorage.setItem(key, storingValue);
    } else {
      global?.window?.localStorage.removeItem(key);
    }
    setValue(storingValue);
  };

  // Listen to local storage changes, in case value is altered by another source
  React.useEffect(() => {
    const listener = (): void => {
      const updatedValue = global?.window?.localStorage.getItem(key);
      if (updatedValue !== value) {
        setValue(updatedValue);
      }
    };
    global?.window?.addEventListener('storage', listener);
    return (): void => {
      global?.window?.removeEventListener('storage', listener);
    };
  });

  return [value, setLocalStorage] as const;
}
