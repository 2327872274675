export interface IListSearchOptions<T> {
  allowPartialMatch?: boolean;
  itemIdentity: (item: T) => string;
  list: T[] | null | undefined;
  query: string;
}

/**
 * Performs a query search through a list.
 * The list is filtered with a case-insensitive, multiple term query.
 *
 * By default, items are only matched if they contain ALL of the specified terms in the query.
 * This behavior can be changed using the "allowPartialMatch" flag.
 */
export function useListSearch<T>(options: IListSearchOptions<T>): T[] {
  const { allowPartialMatch, itemIdentity, list, query } = options;

  if (!list || list.length === 0) return [];
  if (query.trim().length === 0) return list;

  const queryTerms = query.split(' ').map(term => term.toLocaleLowerCase());
  return list.filter(item => {
    const identity = itemIdentity(item).toLocaleLowerCase();
    return allowPartialMatch
      ? queryTerms.some(term => identity.includes(term))
      : queryTerms.every(term => identity.includes(term));
  });
}

export default useListSearch;
