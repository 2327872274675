import { AutocompleteProps } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { isString } from 'lodash';
import { ChangeEvent, ReactElement, ReactNode, SyntheticEvent, useContext, useState } from 'react';

import IconButton from '~/components/IconButton';
import { ITextStrings } from '~/constants/schoolSearch';
import TAG_IDS from '~/constants/tagIds';
import getIcon from '~/helpers/getIcon';
import { Filter, MyStudentsSelectionContext } from '~/providers/MyStudentsSelection';

import Autocomplete from '../Autocomplete';

import { AutocompleteStyled, ChevronDownStyled, OptionDisabled, SearchStyled, SecondColumnOption } from './styles';

const ChevronDown = getIcon('general.chevron.down');
const Close = getIcon('general.close');

export interface IOption {
  id: string;
  label: string;
  label2?: string;
  schoolId?: string;
  schoolCity?: string;
  schoolState?: string;
}

export type OptionType = IOption | string;

export interface IProps extends Partial<AutocompleteProps<OptionType, false, true, false>> {
  isStudentSearch?: boolean;
  hideMenu?: boolean;
  textFieldProps: TextFieldProps;
  setValue: (newValue: OptionType | null) => void;
  setMenuWidth?: (width: number) => void;
  size: 'medium' | 'large';
  texts?: ITextStrings;
}

export const menuHeaderIdPrefix = 'search-menu-header-';
export const menuResultsNumberId = 'menu-results-number';

export const getResultsNumberString = (numberOfResults: number): string => {
  return `${numberOfResults} result${numberOfResults > 1 ? 's' : ''} found`;
};

const Search = function Search(props: IProps): ReactElement<typeof Autocomplete> {
  const { disabled, isStudentSearch = false, hideMenu = false, options, setValue, size, textFieldProps, value, noOptionsText, texts, ...otherProps } = props;
  const selectionContext = useContext(MyStudentsSelectionContext);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value as string);
  const [open, setOpen] = useState<boolean>(false);

  const searchAdornment = (): ReactElement => {
    const endAdornment = !value ? <SearchStyled $isEditing={isEditing} /> : <ChevronDownStyled />;
    return <InputAdornment position="end">{isEditing ? <SearchStyled $isEditing={isEditing} /> : endAdornment}</InputAdornment>;
  };

  const clearAdornment = (
    <InputAdornment position="start">
      <IconButton
        id={TAG_IDS.SCHOOL_SEARCH.SEARCH_COMPONENT_CLEAR_BUTTON}
        onClick={(): void => {
          setInputValue('');
          setOpen(false);
          if (isStudentSearch) {
            setValue('');
            selectionContext.setFilters(Filter.SEARCH_FILTER, '');
          } else {
            setValue(null);
          }
        }}
        sx={{
          marginLeft: '-8px',
          marginRight: '-8px',
        }}
      >
        <Close />
      </IconButton>
    </InputAdornment>
  );

  return (
    <AutocompleteStyled
      disabled={disabled}
      getOptionDisabled={(option: OptionType): boolean => {
        if (isString(option)) return false;
        return option.id.startsWith(menuHeaderIdPrefix);
      }}
      noOptionsText={noOptionsText || 'No results found'}
      onClose={(): void => setOpen(false)}
      onInputChange={(_event: SyntheticEvent, newValue: string, reason: string): void => {
        if (reason === 'input') {
          const foundOption = options?.find(option => {
            if (isString(option)) {
              return option === newValue;
            }
            return option.label === newValue;
          });
          setInputValue(newValue);
          if (foundOption) {
            setValue(foundOption);
          }
          if (newValue === '') {
            setOpen(false);
          }
        }

        if (reason === 'reset') {
          const foundOption = options?.find(option => {
            if (isString(option)) {
              return option === newValue;
            }
            return option.label === newValue;
          });
          setInputValue(newValue);
          if (foundOption) {
            setValue(foundOption);
          }
        }

        // This condition below is NOT related with clear button,
        // we just implement this condition to make sure we don't break MUI default behaviour.
        if (reason === 'clear') {
          setInputValue('');
          setValue(null);
        }
      }}
      onOpen={(event: SyntheticEvent<Element, Event>): void => {
        const { target } = event as ChangeEvent<HTMLInputElement>;
        if (inputValue || target.value) setOpen(true);
      }}
      open={hideMenu ? false : open}
      options={options ?? []}
      popupIcon={<ChevronDown />}
      renderInput={(params): ReactNode => {
        const { label, id, ...otherTextFieldProps } = textFieldProps;
        const textFieldLabel = isEditing ? texts?.inputFocusedPlaceholder : texts?.inputPlaceholder;
        return (
          <TextField
            {...otherTextFieldProps}
            {...params}
            id={id}
            InputProps={{
              ...params.InputProps,
              endAdornment: isEditing || (isStudentSearch && value) ? clearAdornment : searchAdornment(),
              onBlur: (): void => setIsEditing(false),
              onFocus: (): void => setIsEditing(true),
              startAdornment: isEditing ? searchAdornment() : undefined,
            }}
            label={textFieldLabel || label}
            size={size}
          />
        );
      }}
      renderOption={(optionProps, option): ReactNode => {
        if (isString(option)) {
          return <li {...optionProps}>{option}</li>;
        }
        if (option.id.startsWith(menuHeaderIdPrefix)) {
          return <OptionDisabled {...optionProps}>{option.label}</OptionDisabled>;
        }
        if (!option.label2) {
          return <li {...optionProps}>{option.label}</li>;
        }
        return (
          <li {...optionProps}>
            <Grid container justifyContent="space-between">
              <Grid item>{option.label}</Grid>
              <SecondColumnOption>
                <Typography sx={{ color: theme => theme.palette.text.overLight.medium }} variant="body2">
                  {option.label2}
                </Typography>
              </SecondColumnOption>
            </Grid>
          </li>
        );
      }}
      size={size}
      value={inputValue}
      {...otherProps}
    />
  );
};

export default Search;
