import { find } from 'lodash';

import { USER_TYPES } from '~/constants/userTypes';
import {
  DetailedPermissions,
  ApplicationModule,
  IActiveUser,
  GenericPermissions,
  DetailedPermission,
  PermissionModule,
} from '~/types';

export interface IAuthProps {
  detailedPermissions?: DetailedPermissions | null;
  genericPermissions?: GenericPermissions | null;
  userPermissions?: Array<string>;
  me?: IActiveUser | null;
  module?: PermissionModule;
  schoolId?: string;
}

const isApplicationModule = (
  module: PermissionModule,
  userPermissions: Array<string> | null | undefined,
): module is ApplicationModule => {
  if (userPermissions) {
    return userPermissions.includes(module);
  }
  return true;
};

function checkModulePermissions<T extends PermissionModule>(
  module: T,
  school: DetailedPermission | undefined,
  genericPermissions?: GenericPermissions | null,
  userPermissions?: Array<string> | null,
): boolean {
  if (isApplicationModule(module, userPermissions)) {
    if (userPermissions && userPermissions.includes(module)) return true;
    if (school && school?.permissions && module in school.permissions) return !!school.permissions?.[module];
    return !!genericPermissions?.[module];
  }
  return false;
}

export const userHasAccessToModuleOnSecondarySchool = ({
  detailedPermissions,
  genericPermissions,
  me,
  module,
  schoolId,
  userPermissions,
}: IAuthProps): boolean => {
  if (!module) {
    return true;
  }

  if (me?.type === USER_TYPES.LDAP) {
    return true;
  }

  const school: DetailedPermission | undefined = !schoolId
    ? undefined
    : (find(detailedPermissions, dp => dp.organization?.externalId === schoolId) as unknown as DetailedPermission);

  if (detailedPermissions && schoolId && !school) {
    return false;
  }

  return checkModulePermissions(module, school, genericPermissions, userPermissions);
};

export default userHasAccessToModuleOnSecondarySchool;
