import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { isString } from 'lodash';
import React from 'react';

import getIcon, { IconSelector } from '~/helpers/getIcon';

export type IconProps = {
  icon: typeof SvgIcon | IconSelector;
} & SvgIconProps;

const Icon: React.FC<IconProps> = ({ icon, ...otherProps }: IconProps): React.ReactElement => {
  const IconComponent = isString(icon) ? getIcon(icon) : icon;

  return <IconComponent {...otherProps} />;
};

export default Icon;
