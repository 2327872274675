const E4ECustomDimensions = {
  heights: {
    dialogContainer: 32,
    dialogMargin: 44,
    header: 64,
    tableHeader: 48,
    tablePagination: 58,
  },
  widths: {
    datePickerPopper: 277,
    largeDialog: 872,
    leftNavBar: 72,
    listItemIcon: 40,
    mediumDialog: 648,
    pageMainContent: 1320,
  },
};

export default E4ECustomDimensions;
