import { useRouter } from 'next/router';
import React from 'react';

import { analyticsSetUp, analyticsTrack } from '~/helpers/analytics';

interface IProps {
  children: React.ReactNode;
}

const Analytics: React.FC<IProps> = ({ children }: IProps): React.ReactElement<unknown> => {
  const router = useRouter();

  React.useEffect(() => {
    analyticsSetUp();
  }, []);

  React.useEffect(() => {
    const handleRouteChange = (): void => {
      analyticsTrack(router.pathname);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return (): void => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default Analytics;
